import {createSlice} from '@reduxjs/toolkit'

export const organizationsSlice = createSlice({
  name: 'counter',
  initialState: {
    selected: localStorage.getItem('selectedOrganization') || null,
  },
  reducers: {
    selectOrganization: (state, action) => {
      state.selected = action.payload;
      localStorage.setItem('selectedOrganization', action.payload);
    },
    clearSelectOrganization: (state) => {
      state.selected = null;
      localStorage.removeItem('selectedOrganization');
    },
  },
})

export const {selectOrganization, clearSelectOrganization} = organizationsSlice.actions

export default organizationsSlice.reducer
