import * as React from 'react';
import Container from "@mui/material/Container";
import ReactMarkdown from 'react-markdown';
import SEO from "../../components/SEO";

const doc = `
# How to: setup a CRON monitoring

# 1. Add a new Probe

Visit your Probes page and click on the **Add Probe** button. In form that appears, fill in the following fields:

![create new probe](/img/cron1.png)
1. Name: A name for your probe, e.g. "My CRON job"
2. Type: Select "Push" from the dropdown. We will create an HTTP endpoint that your CRON job will call.
3. Schedule: Select "CRON" from the dropdown,
4. Expression: Probe check CRON expression, e.g. "10 0 * * *". This will be used to calculate the next expected call from your CRON job.
5. Failure threshold: The number of consecutive failures before an alert is triggered.
6. Success threshold: The number of consecutive successes before an alert is resolved.
7. Channels: Select the channels you want to receive alerts on.
8. Create the probe.

After creating the probe, you will be redirected to the probes page. Click on the probe **URL** button and copy the URL to your clipboard.

# 2. Setup your CRON job
Add the CURL command to your CRON job to call the probe URL. For example:
\`\`\`sh
10 0 * * * /opt/scripts/backup.sh && curl https://probe.obslabs.io/probe_id
\`\`\`

If your cron job is running e.g. "10 0 * * *" and you expect it to run for 5 minutes, **set the probe expression to "15 0 * * *"**. This will allow for a 5 minute window for the probe to receive the call from your CRON job.

# 3. Monitor the probe
Visit the **Probes** page to see the status of the probe. You can also see the history of the probe by clicking on it.
`;

export default function HowToSetupCronMonitoring() {
  return (
    <>
      <SEO title="ObsLabs | How to: setup a CRON monitoring"  url="/blog/how-to-setup-a-cron-monitoring" description="A guide on how to setup a CRON monitoring system using ObsLabs"/>
      <Container
        id="top"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          pt: {xs: 8, sm: 12},
          pb: {xs: 8, sm: 12},
        }}
      >
        <ReactMarkdown>
          {doc}
        </ReactMarkdown>
      </Container>
    </>
  );
}
