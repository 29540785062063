import {useEffect} from "react";
import {useLocation} from "react-router-dom";

const ScrollToHashElement = () => {
  let location = useLocation();

  let hashElement = () => {
    let hash = location.hash;
    const removeHashCharacter = (str) => {
      return str.slice(1);
    };

    if (hash) {
      return document.getElementById(removeHashCharacter(hash));
    } else {
      return null;
    }
  };

  useEffect(() => {
    const el = hashElement();
    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [hashElement]);

  return null;
};

export default ScrollToHashElement;
