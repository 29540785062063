import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack
} from "@mui/material";
import Typography from "@mui/material/Typography";

export default function ViewPeriodUsage(props) {
  const [open, setOpen] = React.useState(false);
  const {organization} = props;

  return (
    <React.Fragment>
      <Button variant="text" onClick={() => setOpen(true)}>Usage</Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          component: 'form',
        }}
      >
        <DialogTitle>Usage</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The following is the usage for the current month
          </DialogContentText>
          <Stack direction="column" spacing={1} mt={2}>
            <Typography variant="body1">Emails: {organization.period_usage.emails}</Typography>
            <Typography variant="body1">Webhooks: {organization.period_usage.webhooks}</Typography>
            <Typography variant="body1">Slack: {organization.period_usage.slack}</Typography>
            <Typography variant="body1">SMS: {organization.period_usage.sms}</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
