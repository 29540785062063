import {createSlice} from '@reduxjs/toolkit'

export const projectsSlice = createSlice({
  name: 'counter',
  initialState: {
    selected: localStorage.getItem('selectedProject') || null,
  },
  reducers: {
    selectProject: (state, action) => {
      state.selected = action.payload;
      localStorage.setItem('selectedProject', action.payload);
    },
    clearSelectProject: (state) => {
      state.selected = null;
      localStorage.removeItem('selectedProject');
    },
  },
})

export const {selectProject, clearSelectProject} = projectsSlice.actions

export default projectsSlice.reducer
