import * as React from 'react';
import Container from "@mui/material/Container";
import ReactMarkdown from 'react-markdown';
import SEO from "../../components/SEO";

const doc = `
# Set up a CI+CD Pipeline for a startup

## Introduction

While Continuous Integration (CI) and Continuous Deployment (CD) are very broad topics, we will focus just on the essence.
**CI will make you confident about the changes you made in your app**, while the **CD process is what makes you deliver fast**.
A properly set up CI+CD Pipeline has great synergy and brings you speed and confidence in your work. That's what every startup needs.

## Prerequisites

- **GitHub**: You need a GitHub account to host your code. We will use GitHub Actions for CI+CD.
- **Go**: We will use Golang for this example. You can use any language you want, but you need to adjust the steps accordingly.
- **Hetzner**: We will use Hetzner Cloud for hosting our app.
- **Testsuite**: We're a startup, so we do not need or even want 100% coverage. Write unit tests to cover your app's business logic and integration tests covering the app's features.

## Simple CICD.yml GitHub Actions Workflow

Avoid dependencies in actions at all. Use the official actions as much as possible. This will make your pipeline more reliable and secure.
You can easily use SSH and SCP instead of using a third-party action to deploy your app.

Create a file named **.github/workflows/cicd.yml** in your repository with the following content:

\`\`\`yaml
name: CI+CD
on:
  workflow_dispatch: ~
  push:
    branches:
      - main

jobs:
  test_and_build:
    name: Test and Build
    runs-on: ubuntu-latest
    timeout-minutes: 10
    steps:
      - uses: actions/checkout@v4
      - uses: actions/setup-go@v5
        with:
          go-version: '1.23'
          cache-dependency-path: go.sum

      - name: Download modules
        working-directory: server
        run: go mod download

      - name: Run tests
        working-directory: server
        run: make test

      - name: Build
        env:
          GOOS: linux
          GOARCH: arm64
        run: |
          go build -o build/api ./cmd/app

      # If your app loads configuration from a file, write it here
      - name: Write config file
        run: |
          echo "ENV=prod" > .env
          echo "DB_DSN=\${{ secrets.DB_DSN }}" >> .env

      - name: Upload build artifacts
        uses: actions/upload-artifact@v4
        with:
          name: my-app-build
          overwrite: 'true'
          include-hidden-files: 'true'
          if-no-files-found: 'error'
          path: |
            build/
            .env

  deploy:
    name: Deploy server
    needs: build
    runs-on: ubuntu-latest
    strategy:
      matrix:
        server_host: ["12.34.56.78",] # Add more server hosts as needed
    env:
      SERVER_HOST: \${{ matrix.server_host }}
    steps:
      - name: Download build artifacts
        uses: actions/download-artifact@v4
        with:
          name: my-app-build
          path: build/

      - name: Add SSH Key to Known Hosts
        run: |
          mkdir -p ~/.ssh
          ssh-keyscan \${{ env.SERVER_HOST }} >> ~/.ssh/known_hosts

      - name: Deploy
        env:
          PRIVATE_KEY: \${{ secrets.SERVER_PRIVATE_KEY }}
          TARGET_DIR: /home/app/
        run: |
          echo "\${{ env.PRIVATE_KEY }}" > private_key.pem
          chmod 600 private_key.pem
          ssh -i private_key.pem obslabs@\${{ env.SERVER_HOST }} "sudo supervisorctl stop all"
          scp -i private_key.pem -r build/* server/.env obslabs@\${{ env.SERVER_HOST }}:\${{ env.TARGET_DIR }}
          ssh -i private_key.pem obslabs@\${{ env.SERVER_HOST }} "sudo supervisorctl start all"
          rm private_key.pem
\`\`\`
`;

export default function SetupCICDPipeline() {
  return (
    <>
      <SEO title="ObsLabs | Setup startup CI/CD Pipeline"  url="/blog/setup-cicd-pipeline" description="How to setup Golang startup CICD Pipeline" />
      <Container
        id="top"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          pt: {xs: 8, sm: 12},
          pb: {xs: 8, sm: 12},
        }}
      >
        <ReactMarkdown>
          {doc}
        </ReactMarkdown>
      </Container>
    </>
  );
}
