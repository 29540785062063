import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ObsLabsIcon from './ObsLabsIcon';
import {Twitter} from "@mui/icons-material";
import {Link as RouterLink} from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" sx={{color: 'text.secondary', mt: 1}}>
      {'Copyright © '}
      <Link color="text.secondary">
        ObsLabs
      </Link>
      &nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 4, sm: 8},
        py: {xs: 8, sm: 10},
        textAlign: {sm: 'center', md: 'left'},
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: {xs: 'column', sm: 'row'},
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: {xs: '100%', sm: '60%'},
          }}
        >
          <Box sx={{width: {xs: '100%', sm: '60%'}}}>
            <ObsLabsIcon/>
          </Box>
        </Box>
        <Box
          sx={{
            display: {xs: 'none', sm: 'flex'},
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{fontWeight: 'medium'}}>
            Product
          </Typography>
          <Link as={RouterLink} to="/#features" sx={{cursor: 'pointer'}} color="text.secondary" variant="body2">
            Features
          </Link>
          <Link as={RouterLink} to="/#api" sx={{cursor: 'pointer'}} color="text.secondary" variant="body2">
            API First
          </Link>
          <Link as={RouterLink} to="/#pricing" sx={{cursor: 'pointer'}} color="text.secondary" variant="body2">
            Pricing
          </Link>
          <Link as={RouterLink} to="/#faq" sx={{cursor: 'pointer'}} color="text.secondary" variant="body2">
            FAQ
          </Link>
        </Box>
        <Box
          sx={{
            display: {xs: 'none', sm: 'flex'},
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{fontWeight: 'medium'}}>
            Legal
          </Typography>

          <Link as={RouterLink} to="/terms-of-service#top" sx={{cursor: 'pointer'}} color="text.secondary" variant="body2">
            Terms of Service
          </Link>
          <Link as={RouterLink} to="/privacy-policy#top" sx={{cursor: 'pointer'}} color="text.secondary" variant="body2">
            Privacy policy
          </Link>
        </Box>
        <Box
          sx={{
            display: {xs: 'none', sm: 'flex'},
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{fontWeight: 'medium'}}>
            Contact
          </Typography>
          <Link as={RouterLink} to="mailto:contact@obslabs.io" sx={{cursor: 'pointer'}} color="text.secondary" variant="body2">
            contact@obslabs.io
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: {xs: 4, sm: 8},
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>
          <Copyright/>
        </div>
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{justifyContent: 'left', color: 'text.secondary'}}
        >
          <IconButton
            color="inherit"
            size="small"
            href="https://github.com/obslabs-io"
            aria-label="GitHub"
            sx={{alignSelf: 'center'}}
          >
            <FacebookIcon/>
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://www.linkedin.com/company/obslabs/"
            aria-label="LinkedIn"
            sx={{alignSelf: 'center'}}
          >
            <LinkedInIcon/>
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://x.com/ObsLabs_io"
            aria-label="Twitter"
            sx={{alignSelf: 'center'}}
          >
            <Twitter/>
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
