// Start of Selection
import React from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {useLoaderData, useNavigate} from "react-router-dom";
import RemoveIntegrationButton from "../components/RemoveIntegrationButton";
import {detectColorMode} from "../../colorMode";

export function Integrations() {
  const navigate = useNavigate();
  const {selectedProject, selectedOrganization, integrations} = useLoaderData();
  const SLACK_CLIENT_ID = process.env.REACT_APP_SLACK_CLIENT_ID;
  const REDIRECT_URI = process.env.REACT_APP_SLACK_REDIRECT_URI;
  const state = selectedProject.id;
  const slackURL = `https://slack.com/oauth/v2/authorize?client_id=${SLACK_CLIENT_ID}&scope=incoming-webhook&user_scope=&redirect_uri=${REDIRECT_URI}&state=${state}`;
  const mode = detectColorMode();

  return (
    <div>
      <Grid container spacing={2}>
        <Grid size={{xs: 12}}>
          <Typography variant="h4">
            Integrations
          </Typography>
        </Grid>
        <Grid size={{xs: 12}} sx={{my: 2}}>
          <a href={slackURL}>
            <Button variant="text" color="primary">
              Add Slack
            </Button>
          </a>
        </Grid>
        <Grid size={{xs: 12}}>
          <TableContainer>
            <Table sx={{minWidth: 650}} aria-label="slacks table">
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Details</TableCell>
                  <TableCell align="right">Options</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {integrations.map((integration) => (
                  <TableRow
                    key={integration.id}
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                  >
                    <TableCell>Slack</TableCell>
                    <TableCell>
                      {integration.type === 'slack' && (
                        <Typography variant="body2">
                          <strong>Workspace:</strong> {integration.slack.team_name},&nbsp;
                          <strong>Channel:</strong> {integration.slack.channel}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <RemoveIntegrationButton projectID={selectedProject.id} organizationID={selectedOrganization.id}
                                               integration={integration} onSuccess={() => {
                        navigate(`/admin/organizations/${selectedOrganization.id}/projects/${selectedProject.id}/integrations`);
                      }}/>
                    </TableCell>
                  </TableRow>
                ))}
                {integrations.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5} sx={{border: 0, textAlign: 'center'}}>
                      No integrations
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}
