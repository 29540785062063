import * as React from 'react';
import Container from "@mui/material/Container";
import ReactMarkdown from 'react-markdown';
import SEO from "../../components/SEO";

const doc = `
# ObsLabs Tech Stack

## Context

ObsLabs is a project I’m building in my spare time. In my day job, I work as a backend developer.
The tech stack choices are based on my experience and preferences.

## **Hetzner**
I’ve used Hetzner for years and am happy with their quality of service and pricing.
I didn’t choose AWS or GCP because I don’t need the scale or complexity they offer.
Hetzner’s pricing is transparent and predictable, so I don’t have to worry about unexpected, massive bills.

### **Cloudflare**
I use Cloudflare for DNS and security.

### **Grafana Loki + Alloy**
I didn’t want to build my own logging stack with ELK or similar tools. The free 50GB from Grafana Labs is enough for my needs.

# **Backend**
My goal is to focus on building the product. No k8s, Dockerfiles, or Helm charts.
No serverless, because I want control over costs and don’t want to be locked into a specific cloud provider.

Just a Hetzner VPS with Ubuntu and supervisord to manage processes. Committing to the main branch triggers a GitHub Action that runs tests, builds a binary, uploads it to the server, and supervisor restarts the service.

Is it scalable? Yes. It may not scale as easily as K8s with replicas, but it’s a good trade-off between simplicity and scalability. I’ve split the app into five services:
- **api** - serves the main \`api.obslabs.io\` defined in the OpenAPI spec,
- **notify** - sends emails, SMS, webhooks, etc.,
- **probe** - handles the \`probe.obslabs.io\` subdomain, used for client probe status updates,
- **push/pull worker** - checks push/pull probes,

Each service is stateless and can be scaled independently. I can add more VPSs and balance the load if necessary.
I hope I get enough traffic to make this a real concern someday!

For the database, I use self-hosted **PostgreSQL**. It has everything I need, and I’m comfortable with it.

## **Go**
Go is fast and has an excellent standard library. The compatibility promise lets me upgrade without worrying about breaking changes.
As a solopreneur, I love that I can compile a binary in GitHub Actions and deploy it to the server within seconds.

Two libraries that **save me a lot of time**:
- [sqlc](https://sqlc.dev/):  I’m not a fan of ORMs, and sqlc lets me write SQL queries while generating Go code. It works seamlessly with [jackc/pgx](https://github.com/jackc/pgx).
- [oapi-codegen](https://github.com/oapi-codegen/oapi-codegen): generates Go code from the OpenAPI spec. My flow is: design the API in [Stoplight](https://stoplight.io/), generate Go code with oapi-codegen, and implement business logic.

Worth mentioning:
- [testcontainers](https://testcontainers.com/) for integration tests. I can spin up PostgreSQL in docker container in seconds
- **stdlib router** with useful chi middleware like [httplog](https://github.com/go-chi/httplog)

## **Nginx**
I use it as a reverse proxy and for serving static files. Using it because I know it well but recently I'm thinking about replacing it with [Caddy](https://caddyserver.com/).

## **Other**
- AWS SES for sending emails,
- Twilio for sending SMS,
- GitHub Actions for CI/CD,
- recently I started testing [openapi-generator](https://github.com/OpenAPITools/openapi-generator) for generating API SDKs but I have mixed feelings about it. I must spend more time to have a final opinion.

## **Frontend**
As I'm a backend developer, It was a challenge for me to build a frontend. I've chosen **React** because it's popular and I can find a lot of resources online.
I'm using [Material-UI](https://mui.com/) for components. I like that I can use ready-to-use components because I don't have a designer skills.

It's important for me to highlight ObsLabs API-first approach. To present documentation I use [redoc](https://github.com/Redocly/redoc), see [api docs here](https://obslabs.io/docs).

## **Payments**
I use [Paddle](https://paddle.com/) for payments. It's more expensive than Stripe, but it saves me a lot of time. I don’t have to worry about VAT, invoicing, or managing payment gateways.

## **Dogfooding**
I use ObsLabs to monitor ObsLabs. It's a great way to find bugs and improve the product.

# **Conclusion**
I hope this post gives you an idea of how ObsLabs is built and helps you make decisions for your projects. In the future, I may add a new post about setting up a CI/CD pipeline for a startup—stay tuned!

You can reach me at contact@obslabs.io
`;

export default function ObsLabsTechStack() {
  return (
    <>
      <SEO title="ObsLabs | Tech stack and how it's bulit"  url="/blog/tech-stack" description="How it's bulit - Tech stack behind ObsLabs"/>
      <Container
        id="top"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          pt: {xs: 8, sm: 12},
          pb: {xs: 8, sm: 12},
        }}
      >
        <ReactMarkdown>
          {doc}
        </ReactMarkdown>
      </Container>
    </>
  );
}
