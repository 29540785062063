import * as React from 'react';
import Container from "@mui/material/Container";
import ReactMarkdown from 'react-markdown';
import SEO from "../../components/SEO";

const doc = `
# How to: setup a Website or API monitoring

# 1. Add a new Probe

After you sign in to your ObsLabs account, visit your Probes page and click on the **Add Probe** button. In the form that appears, fill in the following fields:

![create new probe](/img/website1.png)
1. Name: A name for your probe, e.g. "My Website"
2. Type: Select "Pull" from the dropdown, this means ObsLabs will reach out to your website or API to check its status.
3. URL: The URL of your website or API. It must be accessible from the internet for GET requests and return a 200-299 status code.
4. Checks schedule: Periodic (time-interval) or CRON expression.
5. If you selected Periodic in the previous step, set the interval in seconds.
6. Failure threshold: The number of consecutive failures before the probe is marked as failed.
7. Success threshold: The number of consecutive successes before the probe is marked as successful.
8. Channels: Select the channels you want to receive notifications on.
9. When you are done, click on the **Create** button.

After creating the probe, you will be redirected to the Probes page. Your Probe status will be "Created" until the first check is performed, based on the schedule you set.
`;

export default function HowToSetupWebsiteMonitoring() {
  return (
    <>
      <SEO title="ObsLabs | How to: setup a Website or API monitoring"  url="/blog/how-to-setup-a-website-or-api-monitoring" description="A guide on how to setup a CRON monitoring system using ObsLabs"/>
      <Container
        id="top"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          pt: {xs: 8, sm: 12},
          pb: {xs: 8, sm: 12},
        }}
      >
        <ReactMarkdown>
          {doc}
        </ReactMarkdown>
      </Container>
    </>
  );
}
