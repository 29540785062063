import React, {useEffect, useRef, useState} from "react";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {generatePath, useLoaderData, useNavigate} from "react-router-dom";
import {DeleteProbe, ListProbes} from "../../api/methods";
import ErrorIcon from '@mui/icons-material/Error';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import ViewProbeURLButton from "../../admin/components/ViewProbeURLButton";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import Link from "@mui/material/Link";

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

export function Probes() {
  const {probes: loadedProbes, selectedProject, selectedOrganization} = useLoaderData();
  const [probes, setProbes] = useState(loadedProbes);
  const navigate = useNavigate();
  const interval = useRef(null);

  const fetchProbes = async () => {
    const [code, resp] = await ListProbes(selectedOrganization.id, selectedProject.id);
    if (code !== 200) {
      throw new Error(`Failed to fetch probes: ${code}`);
    }
    setProbes(resp.probes);
  }

  useEffect(() => {
    interval.current = setInterval(fetchProbes, 10000);
    return () => clearInterval(interval.current);
  }, []);

  const [probeToDelete, setProbeToDelete] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDeleteClick = (probe) => {
    setProbeToDelete(probe);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setProbeToDelete(null);
  };

  const handleConfirmDelete = async () => {
    try {
      const [code] = await DeleteProbe(selectedOrganization.id, selectedProject.id, probeToDelete.id);
      if (code !== 204) {
        throw new Error(`Failed to delete probe: ${code}`);
      }
      setProbeToDelete(null);
      setIsDialogOpen(false);
      await fetchProbes();
    } catch (error) {
      console.error(error);
      // Optionally, add error handling UI here
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid size={{xs: 12}}>
          <Typography variant="h4">
            Probes
          </Typography>
        </Grid>
        <Grid size={{xs: 12}} sx={{my: 2}}>
          <Button
            variant="text"
            onClick={() => navigate(generatePath('/admin/organizations/:organizationID/projects/:projectID/probes/new', {
              organizationID: selectedOrganization.id,
              projectID: selectedProject.id
            }))}
          >
            NEW PROBE
          </Button>
        </Grid>
        <Grid size={{xs: 12}}>
          <TableContainer>
            <Table sx={{minWidth: 650}} aria-label="probes table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell>Last changed</TableCell>
                  <TableCell>Last checked</TableCell>
                  <TableCell>
                    <Tooltip title="Notification channels">
                      <span>Channels</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right">Options</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {probes.map((probe) => (
                  <TableRow
                    hover
                    key={probe.id}
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                  >
                    <TableCell>
                      <Link sx={{cursor: 'pointer'}} underline="hover" onClick={() => navigate(generatePath('/admin/organizations/:organizationID/projects/:projectID/probes/:probeID/view', {
                        organizationID: selectedOrganization.id,
                        projectID: selectedProject.id,
                        probeID: probe.id
                      }))}>
                        {probe.name}
                      </Link>
                      </TableCell>
                    <TableCell>
                      <Stack direction="column" spacing={1} alignItems="center">
                        {probe.status === 'created' && (
                          <Tooltip title="Created">
                            <PendingIcon color="info"/>
                          </Tooltip>
                        )}
                        {probe.status === 'success' && (
                          <Tooltip title="Success">
                            <CheckCircleIcon color="success"/>
                          </Tooltip>
                        )}
                        {probe.status === 'failure' && (
                          <Tooltip title="Failure">
                            <ErrorIcon color="error"/>
                          </Tooltip>
                        )}
                        {probe.status === 'paused' && (
                          <Tooltip title="Paused">
                            <PauseCircleIcon color="warning"/>
                          </Tooltip>
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={probe.status_changed_at ? dayjs(probe.status_changed_at).format('L LT') : 'Status not changed yet'}>
                        <Typography variant="caption">
                          {probe.status_changed_at ? dayjs(probe.status_changed_at).fromNow() : 'N/A'}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={probe.status_checked_at ? dayjs(probe.status_checked_at).format('L LT') : 'Not checked yet'}>
                        <Typography variant="caption">
                          {probe.status_checked_at ? dayjs(probe.status_checked_at).fromNow() : 'N/A'}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        {probe.channels.map((channel) => (
                          <Tooltip key={channel.id} title="Edit channel">
                            <Chip
                              label={channel.name}
                              onClick={() => navigate(generatePath('/admin/organizations/:organizationID/projects/:projectID/channels/:channelID/edit', {
                                organizationID: selectedOrganization.id,
                                projectID: selectedProject.id,
                                channelID: channel.id
                              }))}
                            />
                          </Tooltip>
                        ))}
                      </Stack>
                    </TableCell>
                    <TableCell align="right">
                      <Stack direction="row-reverse" spacing={1}>
                        <Button
                          variant="text"
                          color="error"
                          onClick={() => handleDeleteClick(probe)}
                        >
                          DELETE
                        </Button>
                        <Button
                          variant="text"
                          onClick={() => navigate(generatePath('/admin/organizations/:organizationID/projects/:projectID/probes/:probeID/edit', {
                            organizationID: selectedOrganization.id,
                            projectID: selectedProject.id,
                            probeID: probe.id
                          }))}
                        >
                          EDIT
                        </Button>
                        <ViewProbeURLButton probe={probe}/>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
                {probes.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5} sx={{border: 0, textAlign: 'center'}}>
                      No probes
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete: {probeToDelete?.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>CANCEL</Button>
          <Button
            sx={{color: 'red'}}
            onClick={handleConfirmDelete}
            autoFocus
          >
            YES
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
