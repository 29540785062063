const themeMode = 'themeMode';

const detectColorMode = () => {
    // Check if there is a preferred mode in localStorage
    const savedMode = localStorage.getItem(themeMode);
    if (savedMode && savedMode === 'dark' || savedMode === 'light') {
        return savedMode;
    }

    // If no preference is found, it uses system preference
    const systemPrefersDark = window.matchMedia(
      '(prefers-color-scheme: dark)',
    ).matches;

    return systemPrefersDark ? 'dark' : 'light';
}

const hasSavedColorMode = () => {
    return !!localStorage.getItem(themeMode);
}

const saveColorMode = (mode) => {
    localStorage.setItem(themeMode, mode);
}

const clearColorMode = () => {
    localStorage.removeItem(themeMode);
}

export { detectColorMode, hasSavedColorMode, saveColorMode, clearColorMode };
