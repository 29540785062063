import React from "react";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Tooltip,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {useLoaderData, useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import PendingIcon from "@mui/icons-material/Pending";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

export function ProbeView() {
  const {probe, probeStatusChanges} = useLoaderData();

  return (
    <div>
      <Grid container spacing={2}>
        <Grid size={{xs: 12}}>
          <Typography variant="h4">
            {probe.name}
          </Typography>
          <Typography variant="h6" sx={{mt:2}}>
            Status Changes
          </Typography>
        </Grid>
        <Grid size={{xs: 12}}>
          <TableContainer>
            <Table sx={{minWidth: 650}} aria-label="probes table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Status</TableCell>
                  <TableCell>Changed At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {probeStatusChanges.map((probeStatusChange) => (
                  <TableRow
                    key={probeStatusChange.id}
                  >
                    <TableCell>
                      <Stack direction="column" spacing={1} alignItems="center">
                        {probeStatusChange.new_status === 'created' && (
                          <Tooltip title="Created">
                            <PendingIcon color="info"/>
                          </Tooltip>
                        )}
                        {probeStatusChange.new_status === 'success' && (
                          <Tooltip title="Success">
                            <CheckCircleIcon color="success"/>
                          </Tooltip>
                        )}
                        {probeStatusChange.new_status === 'failure' && (
                          <Tooltip title="Failure">
                            <ErrorIcon color="error"/>
                          </Tooltip>
                        )}
                        {probeStatusChange.new_status === 'paused' && (
                          <Tooltip title="Paused">
                            <PauseCircleIcon color="warning"/>
                          </Tooltip>
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell>
                      {dayjs(probeStatusChange.changed_at).fromNow()} ({dayjs(probeStatusChange.changed_at).format('ll LTS')})
                    </TableCell>
                  </TableRow>
                ))}
                {probeStatusChanges.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5} sx={{border: 0, textAlign: 'center'}}>
                      No status changes yet.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}
