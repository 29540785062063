import {
  Button,
  FormControl, FormHelperText,
  InputLabel,
  MenuItem, OutlinedInput,
  Select, Stack,
  TextField
} from "@mui/material";
import {generatePath, useLoaderData, useNavigate} from "react-router-dom";
import {UpdateProbe, CreateProbe, GetProbe} from "../../api/methods";
import Grid from '@mui/material/Grid2';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import {apiErrorMapper} from "../apiErrorMapper";

export function ProbeForm(params) {
  const {probe: loadedProbe, channels, selectedProject, selectedOrganization} = useLoaderData();
  const navigate = useNavigate();

  const [probe, setProbe] = useState({
    ...loadedProbe,
    channels: loadedProbe.channels ? loadedProbe.channels.map((channel) => channel.id) : [],
  });

  const [errors, setErrors] = useState({});

  const saveProbe = async () => {
    const payload = {
      project_id: probe.project_id,
      name: probe.name,
      type: probe.type,
      schedule: probe.schedule,
      channels: probe.channels,
    };
    if (probe.type === 'pull') {
      payload.url = probe.url;
    }

    let code, resp;
    if (probe.id) {
      [code, resp] = await UpdateProbe(selectedOrganization.id, selectedProject.id, probe.id, payload);
    } else {
      [code, resp] = await CreateProbe(selectedOrganization.id, selectedProject.id, payload);
    }

    const respErrors = apiErrorMapper(code, resp);
    if (respErrors) {
      setErrors(respErrors);
      if (respErrors.alert) {
        alert(respErrors.alert);
      }
      return;
    }

    navigate(generatePath('/admin/organizations/:organizationID/projects/:projectID/probes', {
      organizationID: selectedOrganization.id,
      projectID: selectedProject.id
    }));
  }

  return (
    <Grid container spacing={2}>
      <Grid size={{xs: 12}}>
        <Typography variant="h4" sx={{pl: 1}}>
          {probe.id ? 'Edit probe' : 'New probe'}
        </Typography>
      </Grid>
      <Grid size={{xs: 12}}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >
          <Stack spacing={2}>
            <Grid size={{xs: 12, md: 6, lg: 4, xl: 3}}>
              <TextField error={!!errors?.name} helperText={errors?.name} fullWidth id="outlined-basic" label="Name"
                         variant="outlined" defaultValue={probe.name}
                         onChange={(e) => {
                           setProbe({...probe, name: e.target.value});
                         }}
              />
            </Grid>
            <Grid key={'probe-type'} size={{xs: 12, md: 6, lg: 4, xl: 3}}>
              <FormControl required fullWidth>
                <InputLabel id="type-label">Type</InputLabel>
                <Select
                  disabled={!!probe.id}
                  labelId="type-label"
                  id="type"
                  value={probe.type}
                  label="Type"
                  onChange={(e) => {
                    setProbe({...probe, type: e.target.value});
                  }}
                 variant="outlined">
                  <MenuItem value={"push"}>Push</MenuItem>
                  <MenuItem value={"pull"}>Pull</MenuItem>
                </Select>
                {probe.type === 'push' && (
                <FormHelperText>Push type: you call ObsLabs URL. It will be generated after probe creation.</FormHelperText>
                )}
                {probe.type === 'pull' && (
                <FormHelperText>Pull type: ObsLabs calls your URL. You need to provide URL.</FormHelperText>
                )}
                {!!probe.id && (
                  <FormHelperText>Type cannot be changed for existing probes.</FormHelperText>
                )}
              </FormControl>
            </Grid>
            {((probe.type === 'pull' && probe.id) || (probe.type === 'pull')) && (
              <Grid size={{xs: 12, md: 6, lg: 4, xl: 3}}>
                <FormControl required fullWidth>
                  {probe.type === 'pull' && (
                    <TextField error={!!errors?.url} helperText={errors.url} id="probe-url" label="URL"
                               variant="outlined" defaultValue={probe.url}
                               onChange={(e) => {
                                 setProbe({...probe, url: e.target.value});
                               }}
                    />
                  )}
                  {probe.type === 'push' && probe.id && (
                    <TextField id="probe-url" label="URL" variant="outlined" value={probe.url} disabled/>
                  )}
                </FormControl>
              </Grid>
            )}
            <Grid size={{xs: 12, md: 6, lg: 4, xl: 3}}>
              <Box sx={{border: 1, borderColor: 'grey.500', borderRadius: 1, p: 2}}>
                <Stack spacing={2}>
                  <Grid>
                    <Typography variant="h6">
                      Checks schedule
                    </Typography>
                  </Grid>
                  <Grid>
                    <FormControl required fullWidth>
                      <InputLabel id="schedule-label">Schedule</InputLabel>
                      <Select
                        labelId="schedule-label"
                        id="probe-schedule"
                        value={probe.schedule ? probe.schedule.type : ""}
                        label="Schedule"
                        onChange={(e) => {
                          let schedule = {
                            type: e.target.value, ...{
                              failure_threshold: 1,
                              success_threshold: 1,
                            },
                          }
                          if (e.target.value === "periodic") {
                            schedule.period_seconds = 60;
                          } else if (e.target.value === "cron") {
                            schedule.expression = "*/5 * * * *";
                          }

                          setProbe({
                            ...probe,
                            schedule: schedule,
                          });
                        }}
                      >
                        <MenuItem value={"periodic"}>Periodic</MenuItem>
                        <MenuItem value={"cron"}>Cron</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {probe.schedule && (
                    <Stack spacing={2}>
                      {probe.schedule.type === 'periodic' ? (
                        <FormControl fullWidth>
                          <TextField
                            label="Period seconds"
                            error={!!errors['schedule.period_seconds']}
                            helperText={errors['schedule.period_seconds']}
                            id="probe-schedule-period-seconds"
                            value={probe.schedule.period_seconds}
                            slotProps={{htmlInput: {type: 'number'}}}
                            onChange={(e) => {
                              setProbe({
                                ...probe,
                                schedule: {
                                  ...probe.schedule, ...{
                                    ...probe.schedule,
                                    period_seconds: parseInt(e.target.value)
                                  },
                                },
                              });
                            }}
                          />
                        </FormControl>
                      ) : (
                        <FormControl fullWidth>
                          <TextField
                            required
                            label="Expression"
                            error={!!errors['schedule.expression']}
                            helperText={errors['schedule.expression']}
                            id="probe-schedule-cron-expression"
                            value={probe.schedule.expression}
                            onChange={(e) => {
                              setProbe({
                                ...probe,
                                schedule: {
                                  ...probe.schedule, ...{
                                    ...probe.schedule,
                                    expression: e.target.value
                                  }
                                }
                              });
                            }}
                          />
                        </FormControl>
                      )}
                      <FormControl required fullWidth>
                        <TextField
                          label="Failure threshold"
                          id="probe-schedule-failure-threshold"
                          error={!!errors['schedule.failure_threshold']}
                          helperText={errors['schedule.failure_threshold']}
                          value={probe.schedule.failure_threshold}
                          slotProps={{htmlInput: {type: 'number'}}}
                          onChange={(e) => {
                            setProbe({
                              ...probe,
                              schedule: {
                                ...probe.schedule, ...{
                                  ...probe.schedule,
                                  failure_threshold: parseInt(e.target.value)
                                }
                              }
                            });
                          }}
                        />
                      </FormControl>
                      <FormControl required fullWidth>
                        <TextField
                          label="Success threshold"
                          id="probe-schedule-success-threshold"
                          error={!!errors['schedule.success_threshold']}
                          helperText={errors['schedule.success_threshold']}
                          value={probe.schedule.success_threshold}
                          slotProps={{htmlInput: {type: 'number'}}}
                          onChange={(e) => {
                            setProbe({
                              ...probe,
                              schedule: {
                                ...probe.schedule, ...{
                                  ...probe.schedule,
                                  success_threshold: parseInt(e.target.value)
                                }
                              }
                            });
                          }}
                        />
                      </FormControl>
                    </Stack>
                  )}
                </Stack>
              </Box>
            </Grid>
            <Grid key={'probe-channels'} size={{xs: 12, md: 6, lg: 4, xl: 3}}>
              <Typography variant="h6" sx={{mb: 1}}>
                Channels
              </Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-name-label">Name</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={probe.channels}
                  onChange={(e) => {
                    setProbe({...probe, channels: e.target.value});
                  }}
                  input={<OutlinedInput label="Channel"/>}
                >
                  {channels.map((channel) => (
                    <MenuItem
                      key={channel.id}
                      value={channel.id}
                    >
                      {channel.name}
                    </MenuItem>
                  ))}
                  {channels.length === 0 && (
                    <MenuItem disabled>No channels available</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid size={{xs: 12, md: 6, lg: 4, xl: 3}}>
              <Button variant="text" onClick={() => saveProbe()}
              >{probe.id ? 'SAVE' : 'CREATE'}</Button>
            </Grid>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
}

export async function getProbeLoader({params}) {
  const [organizationID, projectID, probeID] = [params.organizationID, params.projectID, params.probeID];
  const [code, response] = await GetProbe(organizationID, projectID, probeID);
  if (code !== 200) {
    throw new Error(`Failed to load probe: ${code}`);
  }

  return {
    probe: response.probe,
  }
}
