import {createSlice} from '@reduxjs/toolkit'
import {detectColorMode} from "../../../colorMode";

export const colorModeSlice = createSlice({
  name: 'color mode',
  initialState: {
    mode: detectColorMode(),
  },
  reducers: {
    selectColorMode: (state, action) => {
      state.mode = action.payload;
    },
  },
})

export const {selectColorMode} = colorModeSlice.actions

export default colorModeSlice.reducer
