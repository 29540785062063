import {GoogleLogin, useGoogleLogin} from "@react-oauth/google";
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {ThemeProvider} from '@mui/material/styles';
import {SignIn as SignInCall} from "../api/methods";
import {useNavigate, useSearchParams} from "react-router-dom";
import {detectColorMode} from "../colorMode";
import themeLoader from "../theme";
import {Button, Stack} from "@mui/material";

export default function SignIn() {
  const navigate = useNavigate();
  let [searchParams,] = useSearchParams();
  const mode = detectColorMode();

  return (
    <ThemeProvider theme={themeLoader(mode)}>
      <Container component="main" maxWidth="xs">
        <CssBaseline/>
        <Stack
          sx={{
            mt: {xs: 4, sm: 8},
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src="/img/logo.color.svg" alt="ObsLabs Logo" style={{maxWidth: '100%', height: 'auto'}}/>
          <Avatar sx={{mt: {xs: 2, sm:4 }, bgcolor: 'primary.main'}}>
            <LockOutlinedIcon/>
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box noValidate sx={{mt: 4}}>
            <GoogleLogin
              theme={mode === 'dark' ? 'filled_black' : 'filled_blue'}
              useOneTap
              auto_select={true}
              ux_mode={'popup'}
              onSuccess={creds => {
                let payload = {
                  credential: creds.credential,
                };
                if (searchParams.has('to') && searchParams.get('to').includes('/admin/join/')) {
                  payload.from_invitation = true;
                }

                SignInCall(payload).then(([code,]) => {
                  if (code === 204) {
                    if (searchParams.has('to')) {
                      navigate(searchParams.get('to'));
                      return;
                    }
                    navigate('/admin');
                  }
                }).catch(e => {
                  console.log(e);
                });
              }}
              onError={() => {
                console.log('Login Failed');
              }}
            />
          </Box>
        </Stack>
      </Container>
    </ThemeProvider>
  );
}
