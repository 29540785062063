import {FormControl, InputLabel, MenuItem, Select, Stack} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import {useLoaderData, useNavigate} from "react-router-dom";
import {selectProject} from "../features/projects/projectsSlice";
import {selectOrganization} from "../features/organizations/organizationsSlice";
import {useDispatch} from "react-redux";

export default function AppSelector(props) {
  const {selectedProject, projects, organizations, selectedOrganization} = useLoaderData();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChangeProject = (event) => {
    const project = projects.find((project) => project.id === event.target.value);
    dispatch(selectProject(project.id));
    navigate('/admin');
  };

  const handleChangeOrganization = (event) => {
    const organization = organizations.find((organization) => organization.id === event.target.value);
    dispatch(selectOrganization(organization.id));
    navigate('/admin');
  };

  return (
    <Toolbar>
      <Stack spacing={2} sx={{my: 2}}>
        {organizations.length > 1 && (
          <FormControl size="small" sx={{my: 2, width: 200}}>
            <InputLabel id="project-label">Organization</InputLabel>
            <Select
              labelId="project-label"
              id="project-select"
              value={selectedOrganization.id}
              label="Organization"
              onChange={handleChangeOrganization}
            >
              {organizations.map((organization) => (
                <MenuItem key={organization.id} value={organization.id}>{organization.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl size="small" sx={{my: 2, width: 200}}>
          <InputLabel id="project-label">Project</InputLabel>
          <Select
            labelId="project-label"
            id="project-select"
            value={selectedProject.id}
            label="Project"
            onChange={handleChangeProject}
          >
            {projects.map((project) => (
              <MenuItem key={project.id} value={project.id}>{project.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </Toolbar>
  )
}
