import { configureStore } from '@reduxjs/toolkit';
import projectsReducer from './admin/features/projects/projectsSlice';
import organizationsReducer from './admin/features/organizations/organizationsSlice';
import colorModeReducer from './admin/features/colormode/colorModeSlice';

export default configureStore({
  reducer: {
    projects: projectsReducer,
    organizations: organizationsReducer,
    colorMode: colorModeReducer,
  },
})
