import * as React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {UpdateOrganization} from "../../api/methods";

export default function RenameOrganizationButton(props) {
  const [open, setOpen] = React.useState(false);
  const {organization, onSuccess} = props;

  return (
    <React.Fragment>
      <Button variant="text" onClick={() => {
        setOpen(true)
      }}>
        Rename
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            UpdateOrganization(organization.id, formJson).then(() => {
              setOpen(false);
              onSuccess();
            });
          },
        }}
      >
        <DialogTitle>Rename organization</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the new organization name.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setOpen(false)
          }}>Cancel</Button>
          <Button type="submit" variant={"contained"}>SAVE</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
