import * as React from 'react';
import Divider from '@mui/material/Divider';
import Pricing from './components/Pricing';
import FAQ from './components/FAQ';
import ApiAccess from './components/ApiAccess';
import Hero from "./components/Hero";
import Features from "./components/Features";
import SEO from "../components/SEO";

export default function Homepage() {
  return (
    <>
      <SEO title="ObsLabs | Monitoring tools" description="Monitor your websites, applications, background jobs, APIs, and more. Get notified when things go wrong." url="/"/>
      <Hero/>
      <div>
        <Features/>
        <Divider/>
        <ApiAccess/>
        <Divider/>
        <Pricing/>
        <Divider/>
        <FAQ/>
      </div>
    </>
  );
}
