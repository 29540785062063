import IconButton from "@mui/material/IconButton";
import {clearColorMode, detectColorMode, hasSavedColorMode, saveColorMode} from "../colorMode";
import SettingsBrightnessIcon from "@mui/icons-material/SettingsBrightness";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import {Menu, Tooltip} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import {useDispatch} from "react-redux";
import {selectColorMode} from "../admin/features/colormode/colorModeSlice";

export default function ColorModeButton(props) {
  const {setMode, bgFix} = props;
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openColorMenu = Boolean(anchorEl);

  const handleClickColorMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseColorMenu = () => {
    setAnchorEl(null);
  };

  const setColorMode = (newMode) => {
    window.location.hash = '';
    if (newMode === 'system') {
      clearColorMode();
      setMode(detectColorMode());
    } else {
      setMode(newMode);
      saveColorMode(newMode);
    }
    dispatch(selectColorMode(detectColorMode()));
  }

  return (
    <div>
      <Tooltip title="Mode" arrow>
        <IconButton
          sx={{color: bgFix ? 'primary.contrastText' : 'primary'}}
          id="basic-button"
          aria-haspopup="true"
          onClick={handleClickColorMenu}
        >
          {!hasSavedColorMode() ? <SettingsBrightnessIcon/> : (detectColorMode() === 'dark' ? <DarkModeIcon/> :
            <LightModeIcon/>)}
        </IconButton>
      </Tooltip>
      <Menu
        id="color-mode-menu"
        anchorEl={anchorEl}
        open={openColorMenu}
        onClose={handleCloseColorMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Tooltip title="Light" arrow placement={'left'}>
          <MenuItem onClick={() => {
            setColorMode('light');
            handleCloseColorMenu();
          }}>
            <LightModeIcon/>
          </MenuItem>
        </Tooltip>
        <Tooltip title="System" arrow placement={'left'}>
          <MenuItem onClick={() => {
            setColorMode('system');
            handleCloseColorMenu();
          }}>
            <SettingsBrightnessIcon/>
          </MenuItem>
        </Tooltip>
        <Tooltip title="Dark" arrow placement={'left'}>
          <MenuItem onClick={() => {
            setColorMode('dark');
            handleCloseColorMenu();
          }}>
            <DarkModeIcon/>
          </MenuItem>
        </Tooltip>
      </Menu>
    </div>
  );
}
