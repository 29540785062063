import * as React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {AccountDelete} from "../../api/methods";

export default function RemoveAccountButton(props) {
  const [open, setOpen] = React.useState(false);
  const {apiKey, onSuccess} = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="text" color="error" onClick={handleClickOpen}>
        DELETE ACCOUNT
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Account?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{mt:2}}>
            This action cannot be undone. We will also delete all data associated with this account after 30 days.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button id={"remove-org"} onClick={() => {
            AccountDelete().then(([code,]) => {
              if (code === 204) {
                onSuccess();
              } else {
                alert("Failed to delete account, please email us at contact@obslabs.io");
              }
            });
            }} color="error" variant="contained" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
