import * as React from 'react';
import {Navigate, useNavigate} from "react-router-dom";
import {googleLogout} from "@react-oauth/google";
import {SignOut as SignOutCall} from "../api/methods";

export default function SignOut() {
  const navigate = useNavigate();

  googleLogout();
  SignOutCall().then(([code,]) => {
    navigate('/');
  });

  return <div>Signing out...</div>;
}
