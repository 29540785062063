import * as React from 'react';
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";

export default function ObsLabsIcon() {
  const navigate = useNavigate();

  return (
    <Button variant="text" color="inherit" size="small" onClick={() => {navigate('/#top')}}>
      <Typography sx={{color: 'primary.main'}} variant="h5">
        ObsLabs
      </Typography>
    </Button>
  );
}
