import * as React from "react";
import {useLoaderData, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

export default function Join(params) {
  const navigate = useNavigate();
  const {user, organization} = useLoaderData();

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Typography variant="h4">Congratulations!</Typography>
      </Grid>
      <Grid xs={12}>
        <Typography variant="body1">
          You have successfully joined the organization: <strong>{organization.name}</strong>.
        </Typography>
      </Grid>
    </Grid>
  )
}
