import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import {useLoaderData, useNavigate} from "react-router-dom";
import * as React from "react";
import {
  Alert,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TextField, Tooltip,
} from "@mui/material";
import RemoveOrganizationButton from "../../admin/components/RemoveOrganizationButton";
import RenameOrganizationButton from "../../admin/components/RenameOrganizationButton";
import {CreateApiKey, ListApiKeys} from "../../api/methods";
import {useState} from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RemoveApiKeyButton from "../components/RemoveApiKeyButton";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import RemoveAccountButton from "../components/RemoveAccountButton";
import ViewPeriodUsage from "../components/ViewPeriodUsage";

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

export function Account(params) {
  const {user, organizations, apiKeys: loadedApiKeys} = useLoaderData();
  const navigate = useNavigate();
  const [apiKeys, setApiKeys] = useState(loadedApiKeys);
  const [newApiKey, setNewApiKey] = useState(null);

  const organizationRoleMap = new Map();
  for (const organization of organizations) {
    for (const member of organization.members) {
      if (member.id === user.id) {
        organizationRoleMap.set(organization.id, member.role);
      }
    }
  }

  const isManager = (organization) => {
    return organizationRoleMap.get(organization.id) === 'manager';
  }
  const isOwner = (organization) => {
    return organizationRoleMap.get(organization.id) === 'owner';
  }

  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid size={{xs: 12}}>
          <Typography variant="h4">
            My organizations
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={12} lg={4} xl={2} sx={{my: 2}}>
          <Button fullWidth variant="text" onClick={() => {
            navigate('/admin/organizations/new');
          }}>New organization</Button>
        </Grid>
      </Grid>

      <Grid container>
        <Grid size={{xs: 12}}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Members</TableCell>
                  <TableCell>Projects</TableCell>
                  <TableCell>Plan</TableCell>
                  <TableCell align="right">Options</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {organizations.map((organization) => (
                  <TableRow key={organization.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell component="th" scope="row">
                      {organization.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {organizationRoleMap.get(organization.id).charAt(0).toUpperCase() + organizationRoleMap.get(organization.id).slice(1)}
                    </TableCell>
                    <TableCell>
                      <Button variant="text" onClick={() => {
                        navigate('/admin/organizations/' + organization.id + '/members');
                      }}>View ({organization.members.length})</Button>
                    </TableCell>
                    <TableCell>
                      <Button variant="text" onClick={() => {
                        navigate('/admin/organizations/' + organization.id + '/projects');
                      }}>View ({organization.projects.length})</Button>
                    </TableCell>
                    <TableCell>
                      <Button variant="text" onClick={() => {
                        navigate('/admin/organizations/' + organization.id + '/subscription');
                      }}>
                        {organization.is_trial ? 'Trial' : organization.subscription.plan}
                      </Button>
                    </TableCell>
                    <TableCell align="right">
                      <Stack direction="row-reverse" spacing={1}>
                        {isOwner(organization) && <RemoveOrganizationButton onSuccess={() => {
                          navigate('/admin/account');
                        }} organization={organization}/>}
                        {!isOwner(organization) && <Button variant="text" color="error">Leave</Button>}
                        {(isManager(organization) || isOwner(organization)) && <RenameOrganizationButton
                          onSuccess={() => {
                            navigate('/admin/account');
                          }} organization={organization}/>}
                        <ViewPeriodUsage organization={organization}/>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
                {organizations.length === 0 && (
                  <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell colSpan={6}>
                      No organizations
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container>
        <Grid size={{xs: 12}} sx={{mt: {xs: 4, sm: 8}}}>
          <Typography variant="h4">
            API Keys
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={12} lg={4} xl={2} sx={{my: 2}}>
          <Button fullWidth variant="text" onClick={() => {
            CreateApiKey().then(([code, resp]) => {
              if (code === 400) {
                alert(resp.error.message);
              }
              if (code !== 200) {
                return;
              }
              setNewApiKey(resp.api_key);
              ListApiKeys().then(([listCode, listResp]) => {
                setApiKeys(listResp.api_keys);
              });
            })
          }}>New API key</Button>
        </Grid>
      </Grid>
      <Grid container>
        {newApiKey && (
          <Grid size={{xs: 12, md: 9, lg: 6, xl: 4}}>
            <Box sx={{mb: 4}}>
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setNewApiKey(null);
                    }}
                  >
                    <CloseRoundedIcon fontSize="inherit"/>
                  </IconButton>
                }
              >
                New API key created. Please copy it now. You won't be able to see it again.
              </Alert>
              <Box sx={{display: 'flex', alignItems: 'center', mt: 2}}>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={newApiKey.key}
                  slotProps={{
                    input: {
                      readOnly: true,
                      endAdornment: (
                        <Tooltip title="Copy to clipboard">
                          <IconButton
                            onClick={() => navigator.clipboard.writeText(newApiKey.key)}
                          >
                            <ContentCopyIcon/>
                          </IconButton>
                        </Tooltip>
                      ),
                    },
                  }}
                />
              </Box>
            </Box>
          </Grid>
        )}
        <Grid size={{xs: 12}}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Key</TableCell>
                  <TableCell>Last used</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell align="right">Options</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {apiKeys.map((apiKey) => (
                  <TableRow key={apiKey.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell component="th" scope="row">
                      {apiKey.key}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {apiKey.last_used ? dayjs(apiKey.last_used).format('lll') : 'Never'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {dayjs(apiKey.created_at).format('lll')}
                    </TableCell>
                    <TableCell align="right">
                      <RemoveApiKeyButton apiKey={apiKey} onSuccess={() => {
                        setNewApiKey(null);
                        ListApiKeys().then(([code, resp]) => {
                          setApiKeys(resp.api_keys);
                        });
                      }}/>
                    </TableCell>
                  </TableRow>
                ))}
                {apiKeys.length === 0 && (
                  <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell colSpan={3}>
                      No API keys
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container>
        <Grid size={{xs: 12}} sx={{mt: {xs: 4, sm: 8}}}>
          <Typography variant="h4">
            Danger zone
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={12} lg={4} xl={2} sx={{my: 2}}>
          <RemoveAccountButton onSuccess={() => {
            navigate('/admin/sign-out');
          }} />
        </Grid>
      </Grid>
    </Stack>
  );
}
