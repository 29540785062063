import {Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import * as React from "react";
import {useLoaderData, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import InviteButton from "../components/InviteButton";
import {MemberRoleMap} from "../memberRoleMap";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import InviteRevokeButton from "../components/InviteRevokeButton";
import {ResendInvitation} from "../../api/methods";

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

export default function Invitations(params) {
  const navigate = useNavigate();
  const {user, organization, invitations} = useLoaderData();
  const members = organization.members;
  const memberRoleMap = new MemberRoleMap(members);

  const refresh = () => {
    navigate('/admin/organizations/' + organization.id + '/members/invitations');
  }

  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid size={{xs: 12}}>
          <Typography variant="h4">
            {organization.name} <small>invitations ({invitations.length})</small>
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={12} lg={4} xl={2} sx={{my:2}}>
          <InviteButton organization={organization} inviterRole={memberRoleMap.get(user.id)} onSuccess={refresh} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid size={{xs: 12}}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Sent</TableCell>
                  <TableCell align="right">Options</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invitations.map((invitation) => (
                  <TableRow
                    key={invitation.id}
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                  >
                    <TableCell component="th" scope="row">
                      {invitation.email}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {invitation.status.charAt(0).toUpperCase() + invitation.status.slice(1)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {invitation.role.charAt(0).toUpperCase() + invitation.role.slice(1)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {dayjs(invitation.sent_at).fromNow()}
                    </TableCell>
                    <TableCell align="right">
                      <Stack direction="row-reverse" spacing={1}>
                        {(invitation.status === 'pending' && (memberRoleMap.isOwner(user.id) || memberRoleMap.isManager(user.id))) && (
                          <InviteRevokeButton organization={organization} invitation={invitation} onSuccess={refresh} />
                        )}
                        {(invitation.status === 'pending' && dayjs(invitation.sent_at).isBefore(dayjs().subtract(1, 'hour'))) && (
                          <Button onClick={() => {
                            ResendInvitation(organization.id, invitation.id).then(([code, resp]) => {
                              if (code === 200) {
                                refresh();
                              } else {
                                alert("Failed to resend invitation, try again in 1 hour or contact support");
                              }
                            });
                          }}>
                            Resend
                          </Button>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
                {invitations.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      No invitations
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Stack>
  )
}
