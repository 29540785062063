import * as React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {DeleteOrganization} from "../../api/methods";

export default function RemoveOrganizationButton(props) {
  const [open, setOpen] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const {organization, onSuccess} = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="text" color="error" onClick={handleClickOpen}>
        REMOVE
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Remove organization"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone and all organization data will be lost.
          </DialogContentText>
          <DialogContentText id="alert-dialog-description" sx={{mt:2}}>
            Please type the name of the organization (<b>{organization.name}</b>) to confirm.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Organization name"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {
              if (e.target.value === organization.name) {
                setDisabled(false);
              } else {
                setDisabled(true);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button id={"remove-org"} onClick={() => {
            DeleteOrganization(organization.id).then(() => {
              handleClose();
              onSuccess();
            });
          }} color={"error"} variant={"contained"} disabled={isDisabled} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
