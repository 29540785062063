import {Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import * as React from "react";
import {useLoaderData, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import ChangeOrganizationRoleButton from "../components/ChangeOrganizationRoleButton";
import RemoveOrganizationMemberButton from "../components/RemoveOrganizationMemberButton";
import {MemberRoleMap} from "../memberRoleMap";

export default function OrganizationMembers(params) {
  const navigate = useNavigate();
  const {user, organization} = useLoaderData();
  const members = organization.members;
  const memberRoleMap = new MemberRoleMap(members);

  const refresh = () => {
    navigate('/admin/organizations/' + organization.id + '/members');
  }

  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid size={{xs: 12}}>
          <Typography variant="h4">
            {organization.name} <small>members ({members.length})</small>
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={12} lg={4} xl={2} sx={{my:2}}>
          <Button variant="text" onClick={() => navigate('/admin/organizations/' + organization.id + '/members/invitations')}>
            Invitations
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid size={{xs: 12}}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell align="right">Options</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {members.map((member) => (
                  <TableRow
                    key={member.id}
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                  >
                    <TableCell component="th" scope="row">
                      {member.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {member.email}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {member.role.charAt(0).toUpperCase() + member.role.slice(1)}
                    </TableCell>
                    <TableCell align="right">
                      <Stack direction="row-reverse" spacing={1}>
                        {(memberRoleMap.isOwner(user.id) || (memberRoleMap.isManager(user.id) && !memberRoleMap.isOwner(member.id))) &&
                          <RemoveOrganizationMemberButton onSuccess={refresh} organization={organization} member={member} />}
                        {memberRoleMap.isOwnerOrManager(user.id) && <ChangeOrganizationRoleButton member={member} user={user}
                                                                                 organization={organization}
                                                                                 onSuccess={refresh}
                        />}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Stack>
  )
}
