const ProjectRoleOwner = 'owner';
const ProjectRoleMember = 'member';
const ProjectRoleViewer = 'viewer';

const OrganizationRoleOwner = 'owner';
const OrganizationRoleManager = 'manager';
const OrganizationRoleMember = 'member';

export class User {
    constructor(user) {
        this.id = user.id;
        this.name = user.name;
        this.email = user.email;
        this.organizationsRoleMap = new Map();
        this.projectsRoleMap = new Map();

        for (const organizationRole of user.organizations_role) {
            this.organizationsRoleMap.set(organizationRole.organization_id, organizationRole.role);
        }

        for (const projectRole of user.projects_role) {
            this.projectsRoleMap.set(projectRole.project_id, projectRole.role);
        }
    }

    isOrganizationOwnerOrManager(organizationID) {
        const organizationRole = this.organizationsRoleMap.get(organizationID);
        return organizationRole === OrganizationRoleOwner || organizationRole === OrganizationRoleManager;
    }

    canEditProject(organizationID, projectID) {
        if (this.isOrganizationOwnerOrManager(organizationID)) {
            return true;
        }

        const userProjectRole = this.projectsRoleMap.get(projectID);
        return userProjectRole === ProjectRoleOwner;
    }

    isOrganizationOwner(organizationID) {
        return this.organizationsRoleMap.get(organizationID) === OrganizationRoleOwner;
    }

    canManageProjectRole(organizationID, projectID, targetRole) {
        if (this.isOrganizationOwnerOrManager(organizationID)) {
            return true;
        }

        const userProjectRole = this.projectsRoleMap.get(projectID);
        switch (userProjectRole) {
            case ProjectRoleOwner:
                return true;
            case ProjectRoleMember:
                return targetRole === ProjectRoleMember || targetRole === ProjectRoleViewer;
            case ProjectRoleViewer:
                return false;
        }

        return false;
    }
}
