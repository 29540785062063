import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControl,
  InputLabel, MenuItem, Select,
} from "@mui/material";
import {apiErrorMapper} from "../apiErrorMapper";
import {CreateProjectMember} from "../../api/methods";

export default function AddProjectMember(props) {
  const [open, setOpen] = React.useState(false);
  const [role, setRole] = React.useState('');
  const [userId, setUserId] = React.useState('');
  const [error, setError] = React.useState({});
  const {organization, project, canAddOwner, onSuccess} = props;

  const projectMembers = new Map();
  for (const member of project.members) {
    projectMembers.set(member.id, member.role);
  }

  const availableOrganizationMembers = [];
  for (const member of organization.members) {
    if (!projectMembers.has(member.id)) {
      availableOrganizationMembers.push(member);
    }
  }

  const availableRoles = ['member', 'viewer'];
  if (canAddOwner === true) {
    availableRoles.unshift('owner');
  }

  return (
    <React.Fragment>
      <Button variant="text" onClick={() => {
        setOpen(true)
      }}>
        Add Member
      </Button>
      <Dialog
        fullWidth
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            CreateProjectMember(organization.id, project.id, formJson).then(([code, data]) => {
              if (code !== 200) {
                setError(apiErrorMapper(code, data));
              } else {
                setUserId('');
                setError({});
                setOpen(false);
                onSuccess();
              }
            });
          },
        }}
      >
        <DialogTitle>Add project member</DialogTitle>
        { availableOrganizationMembers.length === 0 && <DialogContent>
          <p>No people to add. Add members to the organization first.</p>
        </DialogContent> }
        { availableOrganizationMembers.length > 0 &&
          <div>
        <DialogContent>
          <FormControl sx={{mt:2}} fullWidth>
            <InputLabel id="organization-member-select-label">Organization member</InputLabel>
            <Select
              labelId="organization-member-select-label"
              id="organization-member-select"
              label="Organization member"
              name="user_id"
              value={userId}
              onChange={(event) => {
                setUserId(event.target.value)
              }}
              variant="outlined">
              {availableOrganizationMembers.map((member) => (
                <MenuItem key={member.id} value={member.id}>{member.name} ({member.email})</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{mt:2}} fullWidth>
            <InputLabel id="role-select-label">Role</InputLabel>
            <Select
              labelId="role-select-label"
              id="role-select"
              label="Role"
              name="role"
              value={role}
              onChange={(event) => {
                setRole(event.target.value)
              }}
              variant="outlined">
              {availableRoles.map((role) => (
                <MenuItem key={role} value={role}>{role.charAt(0).toUpperCase() + role.slice(1)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setOpen(false)
          }}>Cancel</Button>
          <Button type="submit" variant="contained">Add</Button>
        </DialogActions>
          </div>
        }
      </Dialog>
    </React.Fragment>
  );
}
