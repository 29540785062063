import * as React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {DeleteProjectMember} from "../../api/methods";

export default function RemoveProjectMemberButton(props) {
  const {organization, project, member, onSuccess} = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="text" color="error" onClick={handleClickOpen}>
        Remove
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Remove project member
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{mt:2}}>
            Are you sure you want to remove {member.name} ({member.email}) from the project?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button id={"remove-org"} onClick={() => {
            DeleteProjectMember(organization.id, project.id, member.id).then(() => {
              handleClose();
              onSuccess();
            });
          }} color="error" variant="contained" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
