import * as React from 'react';
import {
  Button, Chip,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Tooltip
} from "@mui/material";
import {generatePath, useLoaderData, useNavigate} from "react-router-dom";
import {DeleteChannel} from "../../api/methods";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import EmailIcon from '@mui/icons-material/Email';
import WebhookIcon from '@mui/icons-material/Webhook';
import SmsIcon from '@mui/icons-material/Sms';
import {Icon as MdiIcon} from '@mdi/react';
import {mdiSlack} from '@mdi/js';
import {IntegrationMap} from "../integrationMap";

export function Channels(params) {
  const {channels, integrations, selectedProject, selectedOrganization} = useLoaderData();
  const navigate = useNavigate();
  const integrationsMap = new IntegrationMap(integrations);

  const getSlackName = (id) => {
    const integration = integrationsMap.get(id);

    return integration.slack.channel + ' at ' + integration.slack.team_name;
  }

  const [channelToDelete, setChannelToDelete] = useState(null);
  const [open, setOpen] = useState(false);
  const askDelete = (channel) => {
    setChannelToDelete(channel);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid size={{xs: 12}}>
          <Typography variant="h4">
            Channels
          </Typography>
        </Grid>
        <Grid size={{xs: 12}} sx={{my:2}}>
          <Button
            variant="text"
            onClick={() => {
              navigate(generatePath('/admin/organizations/:organizationID/projects/:projectID/channels/new', {
                organizationID: selectedOrganization.id,
                projectID: selectedProject.id
              }));
            }}
          >NEW CHANNEL</Button>
        </Grid>
        <Grid size={{xs: 12}}>
          <TableContainer>
            <Table sx={{minWidth: 650}} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Notifications</TableCell>
                  <TableCell align="right">Options</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {channels.map((channel, idx) => (
                  <TableRow
                    key={idx + '-' + channel.name}
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                  >
                    <TableCell component="th" scope="row">
                      {channel.name}
                    </TableCell>
                    <TableCell>
                      <Grid container spacing={0.2}>
                        {channel.emails && channel.emails.map((email, idx) => (
                          <Grid key={idx + '-email'} display='flex'>
                            <Tooltip title={
                              <React.Fragment>
                                <Typography>{email.name}</Typography>
                              </React.Fragment>
                            }>
                              <Chip variant="outlined" sx={{mx: 0.25}} icon={<EmailIcon fontSize="small"/>}
                                    label={email.email.length > 20 ? email.email.substring(0, 20) + '...' : email.email}/>
                            </Tooltip>
                          </Grid>
                        ))}
                        {channel.webhooks && channel.webhooks.map((webhook, idx) => (
                          <Grid key={idx + '-webhook'} display='flex'>
                            <Tooltip title={
                              <React.Fragment>
                                <Typography>{webhook.name}</Typography>
                                <Typography>{webhook.url}</Typography>
                              </React.Fragment>
                            }>
                              <Chip variant="outlined" sx={{mx: 0.25}} icon={<WebhookIcon fontSize="small"/>}
                                    label={webhook.url.length > 20 ? webhook.url.substring(0, 20) + '...' : webhook.url}/>
                            </Tooltip>
                          </Grid>
                        ))}
                        {channel.slacks && channel.slacks.map((slack, idx) => (
                          <Grid key={idx + '-slack'} display='flex'>
                              <Chip variant="outlined" sx={{mx: 0.25}} icon={<MdiIcon path={mdiSlack} size={1}/>}
                                    label={getSlackName(slack.integration_id)}/>
                          </Grid>
                        ))}
                        {channel.smss && channel.smss.map((sms, idx) => (
                          <Grid key={idx + '-sms'} display='flex'>
                            <Tooltip title={
                              <React.Fragment>
                                <Typography>{sms.name}</Typography>
                                <Typography>{sms.phone}</Typography>
                              </React.Fragment>
                            }>
                              <Chip variant="outlined" sx={{mx: 0.25}} icon={<SmsIcon fontSize="small"/>}
                                    label={sms.phone.length > 20 ? sms.phone.substring(0, 20) + '...' : sms.phone}/>
                            </Tooltip>
                          </Grid>
                        ))}
                      </Grid>
                    </TableCell>
                    <TableCell align="right">
                      <Stack direction="row-reverse" spacing={1}>
                        <Button variant='text' color='error'
                                onClick={() => askDelete(channel)}
                        >DELETE</Button>
                        <Button variant='text' onClick={() => {
                          navigate(generatePath('/admin/organizations/:organizationID/projects/:projectID/channels/:channelID/edit', {
                            organizationID: selectedOrganization.id,
                            projectID: selectedProject.id,
                            channelID: channel.id
                          }));
                        }}
                        >EDIT</Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
                {channels.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>
                      No channels
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete channel: {channelToDelete?.name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CANCEL</Button>
          <Button
            sx={{color: 'red'}}
            onClick={async () => {
              const [code, response] = await DeleteChannel(selectedOrganization.id, selectedProject.id, channelToDelete.id);
              if (code !== 204) {
                throw new Error(`Failed to delete channel: ${code}`);
              }
              setChannelToDelete(null);
              setOpen(false);
              navigate(generatePath('/admin/organizations/:organizationID/projects/:projectID/channels', {
                organizationID: selectedOrganization.id,
                projectID: selectedProject.id
              }));
            }} autoFocus>
            YES
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
