import {
  Button,
  Icon, Stack,
  TextField
} from "@mui/material";
import {useLoaderData, useNavigate, useParams} from "react-router-dom";
import {CreateProject} from "../../api/methods";
import Grid from '@mui/material/Grid2';
import Typography from "@mui/material/Typography";
import {useState} from "react";
import {mdiSlack} from '@mdi/js';

<Icon path={mdiSlack} size={1}/>

export function ProjectForm(params) {
  const {selectedOrganization} = useLoaderData();
  params = useParams();
  const navigate = useNavigate();

  const [project, setProject] = useState({
    name: "My Project",
    organization_id: params.organizationID,
  });

  const saveProject = async () => {
    const payload = {
      name: project.name,
    };

    await CreateProject(project.organization_id, payload);

    navigate('/admin/organizations/' + project.organization_id + '/projects');
  }

  return (
    <div>
      <Stack spacing={2}>
        <Grid container>
          <Grid size={{xs: 12}}>
            <Typography variant="h4">
              Create project
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid size={{xs: 12, md: 6, lg: 4, xl: 3}}>
            <TextField id="outlined-basic" fullWidth label="Name" variant="outlined" required value={project.name}
                       onChange={(e) => {
                         setProject({...project, name: e.target.value});
                       }}
            />
          </Grid>
        </Grid>
        <Grid container>
            <Button variant="text" onClick={saveProject}>Create</Button>
        </Grid>
      </Stack>
    </div>
  );
}
