import {RedocStandalone} from 'redoc';
import SEO from "../components/SEO";

const ApiHost = process.env.REACT_APP_API_HOST;

export default function ApiDocs() {
  return (
    <>
      <SEO title="ObsLabs | API Documentation" description="API Documentation for ObsLabs.io services." url="/docs"/>
      <RedocStandalone
        specUrl={`${ApiHost}/openapi.yml`}
        options={{
          nativeScrollbars: true,
        }}
      />
    </>
  )
}
