import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl,
  InputLabel, MenuItem, Select,
  TextField
} from "@mui/material";
import {SendInvitation} from "../../api/methods";
import {apiErrorMapper} from "../apiErrorMapper";

export default function InviteButton(props) {
  const [open, setOpen] = React.useState(false);
  const [role, setRole] = React.useState('member');
  const [error, setError] = React.useState({});
  const {organization, inviterRole, onSuccess} = props;

  const availableRoles = ['member'];
  if (inviterRole === 'owner') {
    availableRoles.unshift('owner', 'manager');
  } else if (inviterRole === 'manager') {
    availableRoles.unshift('manager');
  }

  return (
    <React.Fragment>
      <Button variant="text" onClick={() => {
        setOpen(true)
      }}>
        Invite
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            SendInvitation(organization.id, formJson).then(([code, data]) => {
              if (code !== 200) {
                setError(apiErrorMapper(code, data));
              } else {
                setError({});
                setOpen(false);
                onSuccess();
              }
            });
          },
        }}
      >
        <DialogTitle>Send invitation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the email address of the user you would like to invite to this organization.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="email"
            name="email"
            label="Email"
            type="text"
            fullWidth
            variant="outlined"
            error={!!error.email}
            helperText={error.email}
          />
          <FormControl sx={{mt:2}} fullWidth>
            <InputLabel id="role-select-label">Role</InputLabel>
            <Select
              labelId="role-select-label"
              id="role-select"
              label="Role"
              name="role"
              value={role}
              onChange={(event) => {
                setRole(event.target.value)
              }}
              variant="outlined">
              {availableRoles.map((role) => (
                <MenuItem key={role} value={role}>{role.charAt(0).toUpperCase() + role.slice(1)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setOpen(false)
          }}>Cancel</Button>
          <Button type="submit" variant={"contained"}>SEND</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
