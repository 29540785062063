 // Start of Selection
import * as React from 'react';
import Container from "@mui/material/Container";
import ReactMarkdown from 'react-markdown';
 import SEO from "../components/SEO";

const subprocessors = `
### Google

- **Sign-In with Google**: Facilitates user authentication through Google accounts.
- **Google Analytics**: Tracks and reports website traffic and user interactions.

### Sentry

- **Error Monitoring**: Captures and tracks application errors and exceptions in real-time.

### Grafana

- **Metrics and Logs**: Provides visualization and monitoring of application metrics and logs.
`;

export default function Subprocessors() {
  return (
    <>
      <SEO
        title="ObsLabs | Subprocessors"
        description="List of subprocessors used by the application."
        url="/subprocessors"
      />
    <Container
      id="top"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: { xs: 8, sm: 12 },
        pb: { xs: 8, sm: 12 },
      }}
    >
      <ReactMarkdown>
        {subprocessors}
      </ReactMarkdown>
    </Container>
    </>
  );
}
