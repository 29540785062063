export class IntegrationMap {
  constructor(integrations) {
    this.integrationsMap = new Map();
    for (const integration of integrations) {
      this.integrationsMap.set(integration.id, integration);
    }
  }

  get(id) {
    return this.integrationsMap.get(id);
  }
}
