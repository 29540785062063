    import * as React from 'react';
    import { Box, Button, Container, Typography, Stack } from '@mui/material';
    import Grid from '@mui/material/Grid2';
    import OpenInNewIcon from '@mui/icons-material/OpenInNew';
    import { styled, useTheme } from '@mui/material/styles';
    import { FaGolang, FaPython, FaCode } from "react-icons/fa6";
    
    // Styled component for SDK items
    const ApiClientItem = styled(Box)(({ theme }) => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(2),
      transition: 'transform 0.3s',
      '&:hover': {
        transform: 'scale(1.05)',
        color: theme.palette.primary.main,
      },
    }));
    
    export default function ApiAccess() {
      const theme = useTheme();
      
      const apiClients = [
        {
          name: 'Golang',
          icon: <FaGolang size={48} color="#00ADD8" />,
        },
        {
          name: 'Python',
          icon: <FaPython size={48} color="#3776AB" />,
        },
        {
          name: 'More soon!',
          icon: <FaCode size={48} color={theme.palette.mode === 'dark' ? '#ffffff' : '#000000'} />,
        },
      ];
    
      return (
        <Box
          id="api"
          sx={{
            py: { xs: 6, sm: 12 },
            bgcolor: 'background.default',
          }}
        >
          <Container maxWidth="lg">
            <Stack spacing={4} alignItems="center" textAlign="center">
              <Typography variant="h2" component="h2" gutterBottom>
                API Access & SDKs
              </Typography>
              <Typography variant="body1" color="text.secondary" maxWidth="md">
                Integrate seamlessly with ObsLabs using our robust API.
                Get started quickly with our ready-to-use API Clients for various programming languages.
              </Typography>
            </Stack>
            <Grid container spacing={4} sx={{ mt: 4, justifyContent: 'center' }}>
              {apiClients.map((apiClient) => (
                <Grid xs={12} sm={6} md={3} key={apiClient.name}>
                  <ApiClientItem>
                    {apiClient.icon}
                    <Typography variant="h6" component="h3" sx={{ mt: 1 }}>
                      {apiClient.name}
                    </Typography>
                  </ApiClientItem>
                </Grid>
              ))}
            </Grid>
            <Box sx={{ mt: 6, textAlign: 'center' }}>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<OpenInNewIcon />}
                  href="/docs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  API Documentation
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  endIcon={<OpenInNewIcon />}
                  href="https://github.com/obslabs-io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  API SDKs
                </Button>
              </Stack>
            </Box>
          </Container>
        </Box>
      );
    }
