import * as React from 'react';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppAppBar from './components/AppAppBar';
import getMPTheme from './theme/getMPTheme';
import TemplateFrame from './TemplateFrame';
import Divider from "@mui/material/Divider";
import Footer from "./components/Footer";
import ScrollToHashElement from "./components/ScrollToHashElement";
import {detectColorMode} from "../colorMode";

export default function Landing(props) {
  const [mode, setMode] = React.useState(detectColorMode());
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const MPTheme = createTheme(getMPTheme(mode));
  const defaultTheme = createTheme({palette: {mode}});
  const {inner} = props;

  React.useEffect(() => setMode(detectColorMode()), []);

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  return (
    <TemplateFrame
      toggleCustomTheme={toggleCustomTheme}
      showCustomTheme={showCustomTheme}
      mode={mode}
    >
      <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
        <CssBaseline enableColorScheme/>
        <AppAppBar setMode={setMode} />
        <ScrollToHashElement/>
        {inner}
        <Divider/>
        <Footer/>
      </ThemeProvider>
    </TemplateFrame>
  );
}
