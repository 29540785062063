import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Link as RouterLink,
  useLoaderData,
} from "react-router-dom";
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import ColorModeButton from "../components/ColorModeButton";
import {detectColorMode} from "../colorMode";
import {ThemeProvider} from '@mui/material/styles';
import themeLoader from './../theme';
import AppSelector from "./components/AppSelector";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';

const drawerWidth = 240;

export default function AdminLayout(props) {
  const {selectedProject, selectedOrganization} = useLoaderData();
  const {inner, isAccountView} = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {window} = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [mode, setMode] = React.useState(detectColorMode());

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const links = [];
  if (isAccountView) {
    links.push({
      text: "Back",
      to: "/admin",
      icon: <ArrowBackIcon/>,
    });
  } else {
    links.push({
      text: 'Probes',
      to: '/admin/organizations/' + selectedOrganization.id + '/projects/' + selectedProject.id + '/probes',
      icon: <MonitorHeartIcon/>,
    }, {
      text: 'Channels',
      to: '/admin/organizations/' + selectedOrganization.id + '/projects/' + selectedProject.id + '/channels',
      icon: <NotificationsIcon/>,
    }, {
      text: 'Integrations',
      to: '/admin/organizations/' + selectedOrganization.id + '/projects/' + selectedProject.id + '/integrations',
      icon: <IntegrationInstructionsIcon/>,
    });
  }

  const drawer = (
    <div>
      {!isAccountView && <AppSelector />}
      <Divider/>
      <List>
        {links.map((link) => (
          <ListItem key={link.text} disablePadding>
            <ListItemButton component={RouterLink} to={link.to} onClick={handleDrawerClose}>
              <ListItemIcon>
                {link.icon}
              </ListItemIcon>
              <ListItemText primary={link.text}/>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider/>
      <List>
        <ListItem key={"account"} disablePadding>
          <ListItemButton component={RouterLink} to={"/admin/account"} onClick={handleDrawerClose}>
            <ListItemIcon>
              <AccountCircleIcon/>
            </ListItemIcon>
            <ListItemText primary={"Account"}/>
          </ListItemButton>
        </ListItem>
        <ListItem key={"signout"} disablePadding>
          <ListItemButton component={RouterLink} to={"/admin/sign-out"}>
            <ListItemIcon>
              <LogoutIcon/>
            </ListItemIcon>
            <ListItemText primary={"Logout"}/>
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <ThemeProvider theme={themeLoader(mode)}>
      <Box sx={{display: 'flex'}}>
        <CssBaseline/>
        <AppBar
          position="fixed"
          sx={{
            width: {sm: `calc(100% - ${drawerWidth}px)`},
            ml: {sm: `${drawerWidth}px`},
          }}
        >
          <Toolbar>
            <Box sx={{flexGrow: 1, display: 'flex', alignItems: 'center', px: 0}}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{mr: 2, display: {sm: 'none'}}}
              >
                <MenuIcon/>
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                ObsLabs
              </Typography>
            </Box>
            <Box>
              <ColorModeButton setMode={setMode} bgFix={mode === 'light'}/>
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
          aria-label="navigation"
        >
          {(isMobile) ? (
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onTransitionEnd={handleDrawerTransitionEnd}
              onClose={handleDrawerClose}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: 'block',
                '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
              }}
            >
              {drawer}
            </Drawer>
          ) : (
            <Drawer
              variant="permanent"
              sx={{
                display: {xs: 'none', sm: 'block'},
                '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
              }}
              open
            >
              {drawer}
            </Drawer>
          )}
        </Box>
        <Box
          component="main"
          sx={{flexGrow: 1, p: 3, width: {sm: `calc(100% - ${drawerWidth}px)`}}}
        >
          <Toolbar/>

          {inner}

        </Box>
      </Box>
    </ThemeProvider>
  );
}
