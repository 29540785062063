import { callApi } from "./callapi";

const SignIn = async (payload = null) => {
  return callApi("/v1/sign-in", "POST", payload);
};

const SignOut = async () => {
  return callApi("/v1/sign-out", "POST", null);
}

// ORGANIZATIONS
const ListOrganizations = async () => {
  return callApi("/v1/organizations", "GET", null);
}

const GetOrganization = async (id) => {
  return callApi(`/v1/organizations/${id}`, "GET", null, null);
}

const CreateOrganization = async (body) => {
  return callApi("/v1/organizations", "POST", body);
}

const UpdateOrganization = async (id, body) => {
  return callApi(`/v1/organizations/${id}`, "PATCH", body, null);
}

const DeleteOrganization = async (id) => {
  return callApi(`/v1/organizations/${id}`, "DELETE", null, null);
}

const UpdateOrganizationMember = async (oID, mID, body) => {
  return callApi(`/v1/organizations/${oID}/members/${mID}`, "PATCH", body, null);
}

const DeleteOrganizationMember = async (oID, mID) => {
  return callApi(`/v1/organizations/${oID}/members/${mID}`, "DELETE", null, null);
}

const ListInvitations = async (oID) => {
  return callApi(`/v1/organizations/${oID}/invitations`, "GET", null, null);
}

const SendInvitation = async (oID, body) => {
  return callApi(`/v1/organizations/${oID}/invitations`, "POST", body, null);
}

const ResendInvitation = async (oID, iID) => {
  return callApi(`/v1/organizations/${oID}/invitations/${iID}/resend`, "POST", null, null);
}

const RevokeInvitation = async (oID, iID) => {
  return callApi(`/v1/organizations/${oID}/invitations/${iID}`, "DELETE", null, null);
}

const AcceptInvitation = async (oID, iID, payload) => {
  return callApi(`/v1/organizations/${oID}/invitations/${iID}/accept`, "POST", payload, null);
}
const ListIntegrations = async (oID, pID) => {
  return callApi(`/v1/organizations/${oID}/projects/${pID}/integrations`, "GET", null, null);
}

const DeleteIntegration = async (oID, pID, iID) => {
  return callApi(`/v1/organizations/${oID}/projects/${pID}/integrations/${iID}`, "DELETE", null, null);
}

const RefreshSubscriptions = async () => {
  return callApi(`/v1/organizations/refresh-subscriptions`, "POST", null, null);
}

const UpdatePaymentMethod = async (oID) => {
  return callApi(`/v1/organizations/${oID}/subscription/update-payment-method`, "POST", null, null);
}

const CleanScheduleChange = async (oID) => {
  return callApi(`/v1/organizations/${oID}/subscription/clean-schedule-change`, "POST", null, null);
}

const CancelSubscription = async (oID) => {
  return callApi(`/v1/organizations/${oID}/subscription/cancel`, "POST", null, null);
}

const UpdateSubscriptionPlan = async (oID, payload) => {
  return callApi(`/v1/organizations/${oID}/subscription/update-plan`, "POST", payload, null);
}

// PROJECTS
const ListProjects = async (organizationID) => {
  return callApi(`/v1/organizations/${organizationID}/projects`, "GET", null, null);
}

const CreateProject = async (organizationID, body) => {
  return callApi(`/v1/organizations/${organizationID}/projects`, "POST", body, null);
}

const UpdateProject = async (organizationID, projectID, body) => {
  return callApi(`/v1/organizations/${organizationID}/projects/${projectID}`, "PATCH", body, null);
}

const DeleteProject = async (organizationID, projectID) => {
  return callApi(`/v1/organizations/${organizationID}/projects/${projectID}`, "DELETE", null, null);
}

const GetProject = async (organizationID, projectID) => {
  return callApi(`/v1/organizations/${organizationID}/projects/${projectID}`, "GET", null, null);
}

const CreateProjectMember = async (organizationID, projectID, body) => {
  return callApi(`/v1/organizations/${organizationID}/projects/${projectID}/members`, "POST", body, null);
}

const UpdateProjectMember = async (organizationID, projectID, memberID, body) => {
  return callApi(`/v1/organizations/${organizationID}/projects/${projectID}/members/${memberID}`, "PATCH", body, null);
}

const DeleteProjectMember = async (organizationID, projectID, memberID) => {
  return callApi(`/v1/organizations/${organizationID}/projects/${projectID}/members/${memberID}`, "DELETE", null, null);
}

// CHANNELS
const ListChannels = async (organizationID, projectID) => {
  return callApi(`/v1/organizations/${organizationID}/projects/${projectID}/channels`, "GET", null, null);
}

const GetChannel = async (organizationID, projectID, channelID) => {
  return callApi(`/v1/organizations/${organizationID}/projects/${projectID}/channels/${channelID}`, "GET", null, null);
}

const CreateChannel = async (organizationID, projectID, body) => {
  return callApi(`/v1/organizations/${organizationID}/projects/${projectID}/channels`, "POST", body, null);
}

const UpdateChannel = async (organizationID, projectID, channelID, body) => {
  return callApi(`/v1/organizations/${organizationID}/projects/${projectID}/channels/${channelID}`, "PATCH", body, null);
}

const DeleteChannel = async (organizationID, projectID, channelID) => {
  return callApi(`/v1/organizations/${organizationID}/projects/${projectID}/channels/${channelID}`, "DELETE", null, null);
}

// PROBES
const ListProbes = async (organizationID, projectID) => {
  return callApi(`/v1/organizations/${organizationID}/projects/${projectID}/probes`, "GET", null, null);
}

const GetProbe = async (organizationID, projectID, probeID) => {
  return callApi(`/v1/organizations/${organizationID}/projects/${projectID}/probes/${probeID}`, "GET", null, null);
}

const CreateProbe = async (organizationID, projectID, body) => {
  return callApi(`/v1/organizations/${organizationID}/projects/${projectID}/probes`, "POST", body, null);
}

const UpdateProbe = async (organizationID, projectID, probeID, body) => {
  return callApi(`/v1/organizations/${organizationID}/projects/${projectID}/probes/${probeID}`, "PATCH", body, null);
}

const DeleteProbe = async (organizationID, projectID, probeID) => {
  return callApi(`/v1/organizations/${organizationID}/projects/${projectID}/probes/${probeID}`, "DELETE", null, null);
}

const ListProbeStatusChanges = async (organizationID, projectID, probeID) => {
  return callApi(`/v1/organizations/${organizationID}/projects/${projectID}/probes/${probeID}/status-changes`, "GET", null, null);
}

// ACCOUNT
const AccountMy = async () => {
  return callApi(`/v1/account`, "GET", null, null);
}

const AccountDelete = async () => {
  return callApi("/v1/account", "DELETE", null, null);
}

// API KEYS
const ListApiKeys = async () => {
  return callApi("/v1/api-keys", "GET", null, null);
}

const CreateApiKey = async () => {
  return callApi("/v1/api-keys", "POST", null, null);
}

const DeleteApiKey = async (id) => {
  return callApi(`/v1/api-keys/${id}`, "DELETE", null, null);
}

export {
  SignIn,
  SignOut,

  ListOrganizations,
  GetOrganization,
  CreateOrganization,
  UpdateOrganization,
  DeleteOrganization,

  UpdateOrganizationMember,
  DeleteOrganizationMember,

  SendInvitation,
  ResendInvitation,
  ListInvitations,
  RevokeInvitation,
  AcceptInvitation,

  RefreshSubscriptions,
  UpdatePaymentMethod,
  CleanScheduleChange,
  CancelSubscription,
  UpdateSubscriptionPlan,

  ListIntegrations,
  DeleteIntegration,

  ListProjects,
  CreateProject,
  UpdateProject,
  DeleteProject,
  GetProject,

  CreateProjectMember,
  UpdateProjectMember,
  DeleteProjectMember,

  CreateChannel,
  UpdateChannel,
  ListChannels,
  GetChannel,
  DeleteChannel,

  ListProbes,
  GetProbe,
  CreateProbe,
  UpdateProbe,
  DeleteProbe,
  ListProbeStatusChanges,

  AccountMy,
  AccountDelete,

  ListApiKeys,
  CreateApiKey,
  DeleteApiKey,
};
