import { generatePath, Navigate, useLoaderData } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectOrganization, clearSelectOrganization } from "./features/organizations/organizationsSlice";
import { selectProject, clearSelectProject } from "./features/projects/projectsSlice";

function Dashboard() {
  const { organizations } = useLoaderData();
  const dispatch = useDispatch();
  const selectedOrganizationID = useSelector((state) => state.organizations.selected);
  const selectedProjectID = useSelector((state) => state.projects.selected);

  if (organizations.length === 0) {
    return <Navigate to="/admin/organizations/new" />;
  }

  const getSelectedOrganization = () => {
    let selectedOrganization = organizations.find((org) => org.id === selectedOrganizationID);
    if (!selectedOrganization) {
      dispatch(clearSelectOrganization());
      selectedOrganization = organizations[0];
      dispatch(selectOrganization(selectedOrganization.id));
    }
    return selectedOrganization;
  };

  const getSelectedProject = (organization) => {
    let selectedProject = organization.projects.find((proj) => proj.id === selectedProjectID);
    if (!selectedProject) {
      dispatch(clearSelectProject());
      selectedProject = organization.projects[0];
      dispatch(selectProject(selectedProject.id));
    }
    return selectedProject;
  };

  const handleNoProjects = (organization) => {
    const orgWithProjects = organizations.find((org) => org.projects.length > 0);
    if (!orgWithProjects) {
      return generatePath('/admin/organizations/:organizationID/projects/new', { organizationID: organization.id });
    }
    dispatch(selectOrganization(orgWithProjects.id));
    return generatePath('/admin/organizations/:organizationID/projects/new', { organizationID: orgWithProjects.id });
  };

  if (!selectedOrganizationID) {
    dispatch(selectOrganization(organizations[0].id));
  }

  const selectedOrganization = getSelectedOrganization();

  if (selectedOrganization.projects.length === 0) {
    return <Navigate to={handleNoProjects(selectedOrganization)} />;
  }

  const selectedProject = getSelectedProject(selectedOrganization);

  const redirectPath = '/admin/organizations/:organizationID/projects/:projectID/probes';
  const path = generatePath(redirectPath, {
    organizationID: selectedOrganization.id,
    projectID: selectedProject.id
  });

  return <Navigate to={path} />;
}

export default Dashboard;
