import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl, FormHelperText, InputLabel, MenuItem, Select,
} from "@mui/material";
import {UpdateOrganizationMember} from "../../api/methods";
import {apiErrorMapper} from "../apiErrorMapper";

export default function ChangeOrganizationRoleButton(props) {
  const [open, setOpen] = React.useState(false);
  const {organization, member, user, onSuccess} = props;
  const [role, setRole] = React.useState('');
  const [error, setError] = React.useState({});

  const availableRoles = ['manager', 'member'];
  if (user.isOrganizationOwner(organization.id)) {
    availableRoles.unshift('owner');
  }

  return (
    <React.Fragment>
      <Button variant="text" onClick={() => {
        setOpen(true)
      }}>
        Role
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            UpdateOrganizationMember(organization.id, member.id, formJson).then(([code, resp]) => {
              if (code !== 200) {
                setError(apiErrorMapper(code, resp));
                return;
              }
              setOpen(false);
              onSuccess();
            });
          },
        }}
      >
        <DialogTitle>Update role</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{mb: 4}}>
            Please select the new role for {member.name} ({member.email})
          </DialogContentText>
          <FormControl error={!!error.role} fullWidth>
            <InputLabel id="role-select-label">Role</InputLabel>
            <Select
              labelId="role-select-label"
              id="role-select"
              label="Role"
              name="role"
              value={role}
              onChange={(event) => {
                setRole(event.target.value)
              }}
             variant="outlined">
              {availableRoles.map((role) => (
                <MenuItem key={role} value={role}>{role.charAt(0).toUpperCase() + role.slice(1)}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{error.role}</FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setOpen(false)
          }}>Cancel</Button>
          <Button type="submit" variant={"contained"}>SAVE</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
