import * as React from 'react';
import {styled, alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ObsLabsIcon from './ObsLabsIcon';
import ColorModeButton from "../../components/ColorModeButton";
import {Link as RouterLink} from "react-router-dom";

const StyledToolbar = styled(Toolbar)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: '8px 12px',
}));

export default function AppAppBar(props) {
  const [open, setOpen] = React.useState(false);
  const {setMode} = props;

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar
      position="fixed"
      sx={{boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 2}}
    >
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          <Box sx={{flexGrow: 1, display: 'flex', alignItems: 'center', px: 0}}>
            <ObsLabsIcon/>
            <Box sx={{display: {xs: 'none', md: 'flex'}}}>
              <RouterLink to="/#features">
                <Button variant="text" color="info" size="small">
                  Features
                </Button>
              </RouterLink>
              <RouterLink to="/#api">
                <Button variant="text" color="info" size="small">
                  API First
                </Button>
              </RouterLink>
              <RouterLink to="/#pricing">
                <Button variant="text" color="info" size="small">
                  Pricing
                </Button>
              </RouterLink>
              <RouterLink to="/#faq">
                <Button variant="text" color="info" size="small" sx={{minWidth: 0}}>
                  FAQ
                </Button>
              </RouterLink>
              <RouterLink to="/blog#top">
                <Button variant="text" color="info" size="small">
                  Blog
                </Button>
              </RouterLink>
            </Box>
          </Box>
          <Box
            sx={{
              display: {xs: 'none', md: 'flex'},
              gap: 1,
              alignItems: 'center',
            }}
          >
            <ColorModeButton setMode={setMode}/>
            <RouterLink to="/admin/sign-in">
              <Button color="primary" variant="contained" size="small">
                Sign in
              </Button>
            </RouterLink>
          </Box>
          <Box sx={{display: {sm: 'flex', md: 'none'}}}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon/>
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{p: 2, backgroundColor: 'background.default'}}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <ColorModeButton setMode={setMode}/>
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon/>
                  </IconButton>
                </Box>
                <Divider sx={{my: 3}}/>
                <MenuItem as={RouterLink} to="/#top" onClick={toggleDrawer(false)}>Home</MenuItem>
                <MenuItem as={RouterLink} to="/#features" onClick={toggleDrawer(false)}>Features</MenuItem>
                <MenuItem as={RouterLink} to="/#api" onClick={toggleDrawer(false)}>API</MenuItem>
                <MenuItem as={RouterLink} to="/#pricing" onClick={toggleDrawer(false)}>Pricing</MenuItem>
                <MenuItem as={RouterLink} to="/#faq" onClick={toggleDrawer(false)}>FAQ</MenuItem>
                <MenuItem as={RouterLink} to="/blog" onClick={toggleDrawer(false)}>Blog</MenuItem>
                <MenuItem>
                  <RouterLink to="/admin/sign-in">
                    <Button color="primary" variant="contained" fullWidth>
                      Sign in
                    </Button>
                  </RouterLink>
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}
