import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import {useLoaderData, useNavigate} from "react-router-dom";
import * as React from "react";
import {
  Button, LinearProgress,
  Stack,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import {initializePaddle, Paddle} from '@paddle/paddle-js';
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import {tiers} from "./tiers";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CardActions from "@mui/material/CardActions";
import {detectColorMode} from "../../colorMode";
import {
  CancelSubscription,
  CleanScheduleChange,
  RefreshSubscriptions,
  UpdatePaymentMethod,
  UpdateSubscriptionPlan
} from "../../api/methods";
import CheckIcon from '@mui/icons-material/Check';

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

const PADDLE_TOKEN = process.env.REACT_APP_PADDLE_TOKEN;
const PADDLE_ENVIRONMENT = process.env.REACT_APP_PADDLE_ENVIRONMENT;

export function OrganizationSubscription(params) {
  const {organization} = useLoaderData();
  const navigate = useNavigate();
  const [paddle, setPaddle] = useState();
  const [changePlan, setChangePlan] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initializePaddle({
      environment: PADDLE_ENVIRONMENT,
      token: PADDLE_TOKEN,
      pwCustomer: organization.subscription?.paddle_customer_id,
      eventCallback: function (data) {
        if (data.name !== "checkout.completed") {
          return;
        }

        RefreshSubscriptions().then(([code, resp]) => {
          if (code === 204) {
            navigate('/admin/organizations/' + organization.id + '/subscription');
          }
        });
      }
    }).then(
      (paddleInstance) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
      },
    );
  }, []);

  const canceled = organization.subscription?.status === 'canceled';
  const scheduledForCancel = organization.subscription?.scheduled_change?.action === 'cancel';

  return (
    <div>
      <Grid container>
        <Grid size={{xs: 12}}>
          <Typography variant="h5">
            {organization.name}
          </Typography>
        </Grid>
      </Grid>

      {!organization.is_trial && (
        <Grid container>
          <Grid size={{xs: 12}}>
            <Typography variant="h5" sx={{mt:2}}>
              Plan: {organization.subscription.plan}, status: {organization.subscription.status}
            </Typography>
            {organization.subscription.next_billed_at && (
              <Typography variant="h6" sx={{mt:2}}>
                Next billing at: {dayjs(organization.subscription.next_billed_at).format('lll')}
              </Typography>
            )}
            {organization.subscription.scheduled_change && (
              <Typography variant="h6" sx={{mt:2}}>
                Scheduled change: {organization.subscription.scheduled_change.action}, effective
                at: {dayjs(organization.subscription.scheduled_change.effective_at).format('lll')}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}

      {loading && (
        <Grid container sx={{mt:4}}>
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        </Grid>
      )}

      {(organization.is_trial || canceled) && (
        <div>
          <Grid container>
            <Grid size={{xs: 12}}>
              <Typography variant="h5">
                Trial ends at: {dayjs(organization.trial_ends_at).format('lll')}
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="h5" sx={{mt: 4, mb: 1}}>
            Choose a plan
          </Typography>
          <Typography variant="body1" sx={{mb: 2}}>
            Cancel possible at any time.
          </Typography>
          <Grid
            container
            spacing={3}
            sx={{alignItems: 'center', justifyContent: 'center', width: '100%'}}
          >
            {tiers.map((tier) => (
              <Grid
                size={{xs: 12, md: 6, lg: 4}}
                key={tier.title}
              >
                <Card
                  sx={[
                    {
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 4,
                    },
                  ]}
                >
                  <CardContent sx={{pb: 0}}>
                    <Box
                      sx={[
                        {
                          mb: 1,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          gap: 2,
                        }
                      ]}
                    >
                      <Typography component="h3" variant="h6">
                        {tier.title}
                      </Typography>
                      {tier.title === 'Core' && (
                        <Chip icon={<AutoAwesomeIcon/>} label={tier.subheader}/>
                      )}
                    </Box>
                    <Box
                      sx={[
                        {
                          display: 'flex',
                          alignItems: 'baseline',
                        },
                      ]}
                    >
                      <Typography component="h3" variant="h2">
                        ${tier.price}
                      </Typography>
                      <Typography component="h3" variant="h6">
                        &nbsp; per month
                      </Typography>
                    </Box>
                    <Divider sx={{my: 2, opacity: 0.8, borderColor: 'divider'}}/>
                    {tier.features.map((line) => (
                      <Box
                        key={line}
                        sx={{py: 1, display: 'flex', gap: 1.5, alignItems: 'center'}}
                      >
                        <CheckCircleRoundedIcon
                          sx={[
                            {
                              width: 20,
                            },
                          ]}
                        />
                        <Typography
                          variant="subtitle2"
                          component={'span'}
                        >
                          {line}
                        </Typography>
                      </Box>
                    ))}
                  </CardContent>
                  <CardActions sx={{justifyContent: 'center', pt: 0}}>
                    <Button variant="contained"
                            onClick={() => {
                              paddle?.Checkout.open({
                                customData: {
                                  organization_id: organization.id,
                                },
                                items: [{
                                  priceId: tier.price_id,
                                  quantity: 1,
                                }],
                                settings: {
                                  theme: detectColorMode(),
                                  showAddDiscounts: false,
                                }
                              });
                            }}
                    >
                      Choose this plan
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      )}

      {changePlan && (
        <div>
          <Typography variant="h5" sx={{mt: 4, mb: 1}}>
            Choose a plan
          </Typography>
          <Grid
            container
            spacing={3}
            sx={{alignItems: 'center', justifyContent: 'center', width: '100%'}}
          >
            {tiers.map((tier) => (
              <Grid
                size={{xs: 12, md: 6, lg: 4}}
                key={tier.title}
              >
                <Card
                  sx={[
                    {
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 4,
                    },
                  ]}
                >
                  <CardContent sx={{pb: 0}}>
                    <Box
                      sx={[
                        {
                          mb: 1,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          gap: 2,
                        }
                      ]}
                    >
                      <Typography component="h3" variant="h6">
                        {tier.title}
                      </Typography>
                      {tier.plan === organization.subscription.plan && (
                        <Chip icon={<CheckIcon/>} label="Current plan"/>
                      )}
                    </Box>
                    <Box
                      sx={[
                        {
                          display: 'flex',
                          alignItems: 'baseline',
                        },
                      ]}
                    >
                      <Typography component="h3" variant="h2">
                        ${tier.price}
                      </Typography>
                      <Typography component="h3" variant="h6">
                        &nbsp; per month
                      </Typography>
                    </Box>
                    <Divider sx={{my: 2, opacity: 0.8, borderColor: 'divider'}}/>
                    {tier.features.map((line) => (
                      <Box
                        key={line}
                        sx={{py: 1, display: 'flex', gap: 1.5, alignItems: 'center'}}
                      >
                        <CheckCircleRoundedIcon
                          sx={[
                            {
                              width: 20,
                            },
                          ]}
                        />
                        <Typography
                          variant="subtitle2"
                          component={'span'}
                        >
                          {line}
                        </Typography>
                      </Box>
                    ))}
                  </CardContent>
                  <CardActions sx={{justifyContent: 'center', pt: 0}}>
                    {tier.plan !== organization.subscription.plan && (
                    <Button variant="contained"
                            onClick={() => {
                              if (window.confirm('Are you sure you want to change the subscription plan? Changing to a higher plan will incur a prorated charge, and changing to a lower plan will take effect at the end of the current billing period.')) {
                                setLoading(true);
                                UpdateSubscriptionPlan(organization.id, {
                                  new_plan: tier.plan,
                                }).then(([code, resp]) => {
                                  setLoading(false);
                                  setChangePlan(false);
                                  if (code === 204) {
                                    navigate('/admin/organizations/' + organization.id + '/subscription');
                                  }
                                });
                              }
                            }}
                    >
                      Choose this plan
                    </Button>
                    )}
                    {tier.plan === organization.subscription.plan && (
                      <Button variant="contained" disabled>
                        Current plan
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      )}

      {!organization.is_trial && (
        <Grid container sx={{mt: 4}}>
          {changePlan && (
            <Stack direction="column" spacing={2}>
              <Button variant="text" onClick={() => {
                setChangePlan(false);
              }}>Cancel</Button>
            </Stack>
          )}
          {(!changePlan && !canceled) && (
            <Stack direction="column" spacing={2}>
              <Button variant="text"
                      onClick={() => {
                        setChangePlan(true);
                      }}
              >Change plan</Button>
              <Button variant="text" onClick={() => {
                setLoading(true);
                UpdatePaymentMethod(organization.id).then(([code, resp]) => {
                  setLoading(false);
                  if (code !== 200) {
                    navigate('/admin/account');
                  }
                  paddle?.Checkout.open({
                    customData: {
                      organization_id: organization.id,
                    },
                    settings: {
                      theme: detectColorMode(),
                      showAddDiscounts: false,
                    },
                    transactionId: resp.transaction_id,
                  });
                });
              }}>Update payment method</Button>
              {!scheduledForCancel ? (
                <Button color="error" variant="text"
                        onClick={() => {
                          if (window.confirm('Are you sure you want to cancel the subscription? It will be canceled at the end of the current billing period. You won\'t be charged again.')) {
                            setLoading(true);
                            CancelSubscription(organization.id).then(([code, resp]) => {
                              if (code === 204) {
                                navigate('/admin/organizations/' + organization.id + '/subscription');
                              }
                              setLoading(false);
                            });
                          }
                        }}
                >Cancel subscription</Button>
              ) : (
                <Button variant="text"
                        onClick={() => {
                          setLoading(true);
                          CleanScheduleChange(organization.id).then(([code, resp]) => {
                            setLoading(false);
                            if (code === 204) {
                              navigate('/admin/organizations/' + organization.id + '/subscription');
                            }
                          });
                        }}
                >Don't cancel</Button>
              )}
            </Stack>
          )}
        </Grid>
      )}
    </div>
  );
}
