const ESSENTIAL_PRICE_ID = process.env.REACT_APP_PADDLE_ESSENTIAL_PRICE_ID;
const CORE_PRICE_ID = process.env.REACT_APP_PADDLE_CORE_PRICE_ID;
const ADVANCED_PRICE_ID = process.env.REACT_APP_PADDLE_ADVANCED_PRICE_ID;

export const tiers = [
  {
    title: 'Essential',
    plan: 'essential',
    price: '9',
    features: [
      '10 probes',
      '4 channels',
      '25 SMS credits',
      '2 projects',
      '3 organization members',
      'Email, slack, and webhook alerts',
      'Priority support',
    ],
    price_id: ESSENTIAL_PRICE_ID,
  },
  {
    title: 'Core',
    plan: 'core',
    subheader: 'Recommended',
    price: '39',
    features: [
      '25 probes',
      '10 channels',
      '120 SMS credits',
      '5 projects',
      '10 organization members',
      'Email, slack, and webhook alerts',
      'Priority support',
    ],
    price_id: CORE_PRICE_ID,
  },
  {
    title: 'Advanced',
    plan: 'advanced',
    price: '99',
    features: [
      '50 probes',
      '25 channels',
      '500 SMS credits',
      '10 projects',
      '25 organization members',
      'Email, slack, and webhook alerts',
      'Priority support',
    ],
    price_id: ADVANCED_PRICE_ID,
  },
];
